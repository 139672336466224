/**
 * 模块名称: 厂商id转移详情页
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  Modal,
  Steps,
  Descriptions,
  Tag,
  DatePicker,
  message,
  Radio
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
let pageSize = 10
let currentPage = 1
// let selectedIds = []
let type = 0

// let transferInQId = 0
// let transferInAgentName = ''
// let transferInAgentPrincipalId = 0
// let transferInAgentPrincipalName = ''
// let transferInClientSaleId = 0
// let transferInClientSaleName = ''
// let transferInClientServiceId = 0
// let transferInClientServiceName = ''
// let clientId = ''

const ManufacturerDetail = (props) => { 
  const { history } = props
  
  const search = parseSearch(props.location.search)
  const [manufacturerData, setManufacturerData] = useState({})
  const [statusList, setStatusList] = useState([])
  const [transferFirmList, setTransferFirmList] = useState([])
  const [detailDataId, setdetailDataId] = useState(0)
  const [drawerTitle, setDrawerTitle] = useState('代理商厂商ID转移申请')
  // const [saveLoading, setSaveLoading] = useState(false)
  const [processShow, setProcessShow] = useState(false)
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [fileList, setFileList] = useState([])
  const [dailishow, setDailishow] = useState(false)
  const [count, setCount] = useState(0)
  
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  // const [affixList, setAffixList] = useState([])
  // const [visible, setVisible] = useState(false)
  const [fundAccountList, setFundAccountList] = useState([])
  const [showProfermanceDateNode, setShowProfermanceDateNode] = useState(false)//是否显示：是否算新开业绩
  const [performanceTimeNode, setPerformanceTimeNode] = useState(undefined)//当是否算新开业绩选择 是 的时候的日期值
  const [nodeTimeDis, setNodeTimeDis] = useState(true)

  useEffect(() => {
      let plaintext = search.sign
      let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
      if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
        setDrawerTitle('厂商ID转移详情')
        setProcessShow(true)
        getDetail(search.id)
      } else {
        message.error('没有本页访问权限')
        setTimeout(() => {
          history.go(-2)
        }, 1000)
      }
  }, [])

  // 获取详情
  const getDetail = (id) => {
    api.agentManufacturerGetDetail({ id: id, type: 0 }, true).then(data => {
      if (search.msgId) {
        api.setMessageReaded({ ids: [search.msgId] })
      }
      setManufacturerData(data)
      setdetailDataId(data.id)
      setShowProfermanceDateNode(data.showProfermanceDateNode)
      // clientId = data.clientId
      type = data.type
      setDailishow(type)
      setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
      setLogConList(data.audit_log) // 审批日志
      setButtonAuthList(data.auditAuth) // 审批权限

      // data.affix.forEach((item, index) => {
      //   fileList.push({
      //     status: 'done',
      //     uid: index,
      //     name: item.name,
      //     url: item.url
      //   })
      // })
      setFileList(data.affix)

      api.agentManufacturerGetTransferFirmList({ 
        oaId: data.auditStatus === 4 ? data.transferInClientId : data.clientId,
        firmInfoId: data.firmInfoIds.join(','),
        page: currentPage,
        limit: pageSize
      }, true).then(tmps => {
        setCount(tmps.count)
        setTransferFirmList(tmps.list)
      })

      var staffIds = []
      if(data.transferInAgentPrincipalId){
        staffIds.push(data.transferInAgentPrincipalId)
      }
      if(data.transferInClientSaleId){
        staffIds.push(data.transferInClientSaleId)
      }
      if(data.transferInClientServiceId){
        staffIds.push(data.transferInClientServiceId)
      }
      staffIds = staffIds.join(',')

      // api.agentManufacturerGetTransferInAccount({ agentId: data.transferInQId, clientId: data.clientId, type: data.type, staffIds: staffIds}, true).then(tmps => {
      //   setFundAccountList(tmps)
      // })
      api.agentManufacturerGetTransferInAccount({ agentId: data.transferInQId, clientId: data.clientId, type: data.type, saleId: data.transferInClientSaleId, serviceId:data.transferInClientServiceId, agentStaffId: data.transferInAgentPrincipalId }, true).then(tmps => {
        setFundAccountList(tmps)
      })
    })
  }
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  //审批类型 0通过 1驳回 2转上级 3补充资料
  // 转上级
  const onTurnUp = () => {
    api.agentManufacturerAudit({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(() => {
      history.push('/agent/manufacturer')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.agentManufacturerAudit({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(() => {
      history.push('/agent/manufacturer')
    }).catch()
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }
  //驳回
  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }

    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.agentManufacturerAudit({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(() => {
      getDetail(search.id) // 确认驳回
    })
    setTurnModalVisible(false)
  }
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }
  //通过
  const onApprove = () => {
    let params = {
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }

    //是否算新开业绩
    if(showProfermanceDateNode){
      if(!nodeTimeDis){
        if(!performanceTimeNode){
          return message.warning('请选择算新开业绩日期')
        }
        params.nodeTime = moment(performanceTimeNode).format('YYYY-MM-DD')
      }
    }
    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.agentManufacturerAudit(params).then(() => {
      getDetail(search.id)  // 确认通过后
    })
    setModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  //是否算新开业绩
  const getPerformanceTimeStatus = e =>{
    setNodeTimeDis(e.target.value == 1 ? false : true)
    if(e.target.value == 0)setPerformanceTimeNode(undefined)
  }
  //是否算新开业绩->是->选择日期
  const getPerformanceTimeNode = e =>{
    setPerformanceTimeNode(e)
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    api.agentManufacturerGetTransferFirmList({ 
      oaId: manufacturerData.auditStatus === 4 ? manufacturerData.transferInClientId : manufacturerData.clientId, 
      firmInfoId: manufacturerData.firmInfoIds.join(','),
      page: currentPage,
      limit: pageSize
    }, true).then(tmps => {
      setCount(tmps.count)
      setTransferFirmList(tmps.list)
    })
  }

  return (
    <>
      <div className="pro-detail pro-integralManage-detail">

      <Descriptions title={drawerTitle} layout="vertical" column={3}>
          <Descriptions.Item label="QID">{manufacturerData.qId}</Descriptions.Item>
          <Descriptions.Item label="代理商名称">{manufacturerData.agentName}</Descriptions.Item>
          <Descriptions.Item label="代理商客户名称">{manufacturerData.clientName}</Descriptions.Item>
      </Descriptions>
      <div className="line-box"></div>

        {processShow ?
        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
        : null}

        {processShow ?
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        : null}

        {processShow ?
        <div className="line-box"></div>
        : null}
        
        <Descriptions title="转出信息" className="pro-detail-smailtitle"></Descriptions>
        <Table
          dataSource={transferFirmList}
          rowKey="id"
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{x: 1400}}
          onChange={onChangeTable}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="厂商账号" dataIndex="account" />
          <Column title="厂商客户名称" dataIndex="vendorClient" />
          <Column title="关联主体" dataIndex="relBranch" />
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="供应商" dataIndex="supplierName" />
          <Column title="最后续费订单" dataIndex="lastOrderNo" />
          <Column title="创建时间" dataIndex="createTime" />
          <Column title="所属渠道代理商开发" dataIndex="relAgentStaffName" />
        </Table>
        <div className="line-box"></div>
        
        <Descriptions title="转入信息" layout="horizontal" column={3} style={{paddingTop: '20px'}}>
            <Descriptions.Item label="客户类型">
            {manufacturerData.type == '0' ? '直销' : '代理商'}
            </Descriptions.Item>

            {dailishow ?
            <Descriptions.Item label="代理商名称">
            {manufacturerData.transferInAgentName}
            </Descriptions.Item>
            : <Descriptions.Item label="客户名称">
            {manufacturerData.transferInClientName}
            </Descriptions.Item>}

            {dailishow ?
            <Descriptions.Item label="">
              {}
            </Descriptions.Item>
            : null}

            {!dailishow ?
            <Descriptions.Item label="">
            </Descriptions.Item>
            : null}

            {/* {!dailishow ?
            <Descriptions.Item label="">
            </Descriptions.Item>
            : null} */}

            {dailishow ? null
            :
            <Descriptions.Item label="所属销售">
            {manufacturerData.transferInClientSaleName}
            </Descriptions.Item>
            }

            {dailishow ? null
            :
            <Descriptions.Item label="所属客服">
            {manufacturerData.transferInClientServiceName}
            </Descriptions.Item>
            }

            <Descriptions.Item label="所属代理商开发">
            {manufacturerData.transferInAgentPrincipalName}
            </Descriptions.Item>

        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="转入主体" className="pro-detail-smailtitle">
          {manufacturerData.accountName && <dItem label="">
            <div className="mainNameBodySel">
              <img className="mainNameBodySelLogo" src={manufacturerData.logo} alt="" />
              {manufacturerData.accountName}
              <Icon className="check" type="check" />
            </div>
          </dItem>}
        </Descriptions>
        <div className="line-box"></div>       
        <Descriptions title="其它信息" className="pro-detail-smailtitle" layout="vertical" style={{paddingTop: '20px'}} column={1}>
          <dItem label="附件">
          <div className="content">
            {
                fileList.length ? fileList.map((item, index) => {
                    return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
            }
          </div>
          </dItem>
          {showProfermanceDateNode == true && <dItem label="是否算新开消耗业绩">
            <Radio.Group onChange={getPerformanceTimeStatus} defaultValue={0}>
              <Radio value={1}>是 <DatePicker disabled={nodeTimeDis} value={performanceTimeNode} onChange={getPerformanceTimeNode} /></Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          </dItem>}
          <dItem label="备注">
            {manufacturerData.remark ? manufacturerData.remark : '无'}
          </dItem>
        </Descriptions>
          <div className="line-box"></div>
          
          {processShow ?
          <Descriptions
            title="审批记录"
            layout="horizontal"
            className="pro-detail-smailtitle"
            style={{paddingBottom: '15px'}}
          >
          </Descriptions>
          : null}

          {processShow ?
          <Table
            dataSource={logConList}
            rowKey="id"
            pagination={false}
            style={{paddingBottom: '15px'}}
          >
            <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
            <Column title="审批时间" dataIndex="addTime" />
            <Column title="状态" dataIndex="status" />
            <Column title="备注" dataIndex="chargeMsg" />
          </Table>
          : null}
          
          {manufacturerData.auditStatusName ? 
          <Descriptions title="" className="pro-detail-smailtitle">
            <dItem label="当前状态">
              <Tag color="#2db7f5">{manufacturerData.auditStatusName}</Tag>
            </dItem>
          </Descriptions>
          : null}
          
          <div className="line-box"></div>
          <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            title={turnNode()}
            // title="确认驳回"
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>

          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default Form.create()(ManufacturerDetail)