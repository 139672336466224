/**
 * 模块名称: 代理商名称变更详情
 * @author xuxiaobo@372163.com
 */

import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Drawer, Form, Icon, Input, Modal, Steps, Table, Tag, message} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import CryptoJS from 'crypto-js'

const {Step} = Steps;
const {Column} = Table

const AgentNameDetail = (props) => {
    const {history} = props
    const search = parseSearch(props.location.search)
    const [detailData, setDetailData] = useState({})
    const [statusList, setStatusList] = useState([])
    const [logConList, setLogConList] = useState([])
    const [detailDataId, setdetailDataId] = useState('')
    const [buttonAuthList, setButtonAuthList] = useState({})
    const [passModalVisible, setModalVisible] = useState(false) //确认弹框
    const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
    const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
    const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
    const [pasDesc, setPasDesc] = useState('')  //通过意见
    const [affixList, setAffixList] = useState([])
    const [hetongVsible, setHetongVsible] = useState(false)
    const [hetongList, setHetongList] = useState([])

    useEffect(() => {
        let plaintext = search.sign
        let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
        if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
            api.agentChangeGetDetail({id: search.id, type: 0}, true).then(data => {
                if (search.msgId) {
                    api.setMessageReaded({ ids: [search.msgId] })
                }
                api.getCompanyinfo({id: data.corpId}, true).then(tmps => {
                    data.corpName = tmps.name
                    setDetailData(data)
                })
    
                setdetailDataId(data.id)
                setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
                setLogConList(data.audit_log) // 审批日志
                setButtonAuthList(data.auditAuth) // 审批权限
                setAffixList(data.affix)
            })
        } else {
            message.error('没有本页访问权限')
            setTimeout(() => {
            history.go(-2)
            }, 1000)
        }
    }, [])

    //审批类型 0通过 1驳回 2转上级 3补充资料
    // 转上级
    const onTurnUp = () => {
        api.agentChangeAudit({
            id: detailDataId,
            type: 2,
            remark: ''
        }).then(data => {
            history.push('/agent/nameManage')
        }).catch()
    }

    // 补充资料
    const onReplenish = () => {
        api.agentChangeAudit({
            id: detailDataId,
            type: 3,
            remark: ''
        }).then(data => {
            history.push('/agent/nameManage')
        }).catch()
    }

    // 驳回
    const onBtnTurnShow = () => {
        setTurnModalVisible(true)
        setTextDisplay(true)
    }

    const onTurnCancel = () => {
        setTurnModalVisible(false)
    }

    // 驳回样式
    const turnNode = () => {
        return <>
            确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C"/>
        </>
    }

    // 同意样式
    const agreeNode = () => {
        return <>
            确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
        </>
    }

    const onTurnApprove = () => {
        if (buttonAuthList.reject_msg_require) {
            if (textAreaVal.trim() === '') {
                setTextDisplay(false)
                return
            }
        }

        //审批类型 0通过 1驳回 2转上级 3补充资料
        api.agentChangeAudit({
            id: detailDataId,
            type: 1,
            remark: textAreaVal
        }).then(data => {
            api.agentChangeGetDetail({id: search.id, type: 0}, true).then(data => {
                setDetailData(data)

                setdetailDataId(data.id)
                setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
                setLogConList(data.audit_log) // 审批日志
                setButtonAuthList(data.auditAuth) // 审批权限
                setAffixList(data.affix)
            }) // 确认驳回
        })
        setTurnModalVisible(false)
    }  // 通过
    const onBtnPassShow = () => {
        setModalVisible(true)
    }

    const onPassCancel = () => {
        setModalVisible(false)
    }

    const onPassDescChange = (e) => {
        setPasDesc(e.target.value)
    }

    const onApprove = () => {
        //审批类型 0通过 1驳回 2转上级 3补充资料
        api.agentChangeAudit({
            id: detailDataId,
            type: 0,
            remark: pasDesc
        }).then(data => {
            api.agentChangeGetDetail({id: search.id, type: 0}, true).then(data => {
                setDetailData(data)

                setdetailDataId(data.id)
                setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
                setLogConList(data.audit_log) // 审批日志
                setButtonAuthList(data.auditAuth) // 审批权限
                setAffixList(data.affix)
            }) // 确认通过后
        })
        setModalVisible(false)
    }

    const onDescChange = (e) => {
        setTextAreaVal(e.target.value)
        setTextDisplay(true)
    }

    const showHetong = (contract) => {
        if(contract){
            api.agentGetContractList({contract})
                .then((res) => {
                    setHetongList(res.list)
                    setHetongVsible(true)
                })
        }else{
            setHetongList([])
            setHetongVsible(true)
        }

    }

    return (
        <>
            <div className="pro-detail">
                <Descriptions title="信息变更详情" layout="horizontal " className="pro-detail-title"></Descriptions>

                {detailData.type == 1 ?
                    <Descriptions title="" layout="horizontal" column={2}>
                        <Descriptions.Item label="代理商名称">{detailData.oldName}</Descriptions.Item>
                        <Descriptions.Item label="合同期限"><a onClick={() => showHetong(detailData.newContract)}>查看</a></Descriptions.Item>
                    </Descriptions> :
                    <Descriptions title="" layout="horizontal" column={2}>
                        <Descriptions.Item label="QID">{detailData.qId}</Descriptions.Item>
                        <Descriptions.Item label="代理商名称">{detailData.oldName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码类型">{detailData.oldVerifyTypeName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码">{detailData.oldVerifyNumber}</Descriptions.Item>
                    </Descriptions>
                }

                <div className="line-box"></div>

                <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
                <div className="promotionProcess">
                    <Steps>
                        {
                            statusList.map((item, index) => {
                                let status = 'wait'
                                if (item.time && item.time.length > 1) {
                                    status = 'process'
                                }
                                return <Step status={status}
                                             key={index}
                                             value={item.time}
                                             title={item.nodeName}
                                             description={
                                                 <>
                                                     {item.persons && <div
                                                         title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                                                     <div title={item.statusName}>{item.statusName}</div>
                                                     <div title={item.time}>{item.time}</div>
                                                 </>
                                             }
                                />
                            })
                        }
                    </Steps>
                </div>
                {detailData.type == 0 && <>
                    <div className="line-box"></div>

                    <Descriptions title="变更前信息" className="pro-detail-smailtitle" layout="vertical" column={4}>
                        <Descriptions.Item label="QID">{detailData.qId}</Descriptions.Item>
                        <Descriptions.Item label="代理商名称">{detailData.oldName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码类型">{detailData.oldVerifyTypeName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码">{detailData.oldVerifyNumber}</Descriptions.Item>
                    </Descriptions>
                    <div className="line-box"></div>

                    <Descriptions title="变更后信息" className="pro-detail-smailtitle" layout="vertical" column={2}>
                        <Descriptions.Item label="QID">{detailData.qId}</Descriptions.Item>
                        <Descriptions.Item label="代理商名称">{detailData.newName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码类型">{detailData.newVerifyTypeName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码">{detailData.newVerifyNumber}</Descriptions.Item>
                    </Descriptions>
                    <div className="line-box"></div>

                    <Descriptions title="" layout="vertical">
                        <Descriptions.Item label="附件">
                            {/*<dItem label="附件" span={3}>*/}
                                <div className="content">
                                    {
                                        affixList.length ? affixList.map((item, index) => {
                                            return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                                        }) : '无'
                                    }
                                </div>
                            {/*</dItem>*/}
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="line-box"></div>

                    <Descriptions title="" layout="vertical">
                        <Descriptions.Item label="备注">{detailData.remark ? detailData.remark : '无'}</Descriptions.Item>
                    </Descriptions>
                </>}
                <div className="line-box"></div>

                <Descriptions
                    title="审批记录"
                    layout="horizontal"
                    className="pro-detail-smailtitle"
                >
                </Descriptions>
                <Table
                    dataSource={logConList}
                    rowKey="id"
                    pagination={false}
                >
                    <Column title="申请人/审批人" dataIndex="userInfo.staffName"/>
                    <Column title="审批时间" dataIndex="addTime"/>
                    <Column title="状态" dataIndex="status"/>
                    <Column title="备注" dataIndex="chargeMsg"/>
                </Table>

                <div className="btn-setting">
                    <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
                    <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
                    <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
                    <Modal
                        title={turnNode()}
                        // title="确认驳回"
                        visible={turnModalVisible}
                        onOk={onTurnApprove}
                        onCancel={onTurnCancel}
                    >
                        <div>
                            <span>审批意见:</span>
                            <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange}
                                            maxLength={100}/>
                        </div>
                        <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
                    </Modal>
                    <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
                    <Modal
                        // title="确认通过"
                        title={agreeNode()}
                        visible={passModalVisible}
                        onOk={onApprove}
                        onCancel={onPassCancel}
                    >
                        <div>
                            <span>审批意见:</span>
                            <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange}
                                            maxLength={100}/>
                        </div>
                    </Modal>
                </div>
                {/*<div className="line-box"></div>*/}

                <Descriptions title="" layout="horizontal">
                    <Descriptions.Item label="当前状态">
                        <Tag color="#2db7f5">{detailData.auditStatusName}</Tag>
                    </Descriptions.Item>
                </Descriptions>

            </div>
            <Drawer
                title="合同明细记录表"
                width={1000}
                onClose={() => setHetongVsible(false)}
                visible={hetongVsible}
            >
                <Table
                    dataSource={hetongList}
                    rowKey="number"
                    className="table-page"
                    scroll={{x: 900}}
                    pagination={false}
                >
                    <Column title="序号" dataIndex="number"/>
                    <Column title="合同号" dataIndex="contractNo"/>
                    <Column title="关联主体" dataIndex="accountName"/>
                    <Column title="项目" dataIndex="projectNames"/>
                    <Column title="产品" dataIndex="productNames"/>
                    <Column title="合同领取人" dataIndex="receiver"/>
                    <Column title="负责人" dataIndex="leadCadreName"/>
                    <Column title="合同期限" render={(record) => {
                        return record.beginTime + ' ~ ' + record.endTime
                    }}/>
                </Table>
            </Drawer>
        </>
    )
}

export default Form.create()(AgentNameDetail)