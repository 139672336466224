/**
 * 模块名称: 代理商信息变更驳回重提
 * @author xuxiaobo@372163.com
 */

import React, {useEffect, useRef, useState} from 'react'
import {Col, Descriptions, Form, Icon, Input, message, Row, Select, Spin, Steps, Table, Tag, Upload,} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const {Step} = Steps;
const {Column} = Table
const Option = Select.Option
const {Dragger} = Upload;

const AgentNameEdit = (props) => {
    const {history} = props
    const {getFieldDecorator, validateFields} = props.form
    const search = parseSearch(props.location.search)
    const [detailData, setDetailData] = useState({})
    const [statusList, setStatusList] = useState([])
    const [logConList, setLogConList] = useState([])
    // const [detailDataId, setdetailDataId] = useState('')
    const [buttonAuthList, setButtonAuthList] = useState({})
    const [verifyTypeList, setVerifyTypeList] = useState([])
    const [fileList, setFileList] = useState([])
    const draggerRef = useRef(null)
    const [saveLoading, setSaveLoading] = useState(false)
    const [contractsList, setContractsList] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        api.agentChangeGetDetail({id: search.id, type: 0}, true).then(data => {
            setDetailData(data)
            // setdetailDataId(data.id)
            setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
            setLogConList(data.audit_log) // 审批日志
            setButtonAuthList(data.auditAuth) // 审批权限

            data.affix.forEach((item, index) => {
                fileList.push({
                    status: 'done',
                    uid: index,
                    name: item.name,
                    url: item.url
                })
            })
            setFileList(data.affix.map((item, index) => ({...item, uid: index})))
        })

        api.getCommon({type: 'verify_type'}, true).then(tmps => {
            setVerifyTypeList(tmps)
        })
    }, [])

    const onSearchContract = (value) => {
        if (!value) {
            setContractsList([])
        }
        setFetching(true)
        api.agentGetRenewContractList({qId: detailData.qId, likeContractNo: value})
            .then(res => {
                setFetching(false)
                setContractsList(res)
            })
    }

    // 文件上传
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png, .jpeg, .bmp',
		allowSizeType: 2
	})

    // 保存
    const formSubmits = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {

            if (!err) {
                setSaveLoading(true)
                let res = ''
                if (vals.contracts != undefined) {
                    let submitVals = {
                        qId: detailData.qId,
                        newContract: vals.contracts,
                        id: detailData.id
                    };

                    res = api.agentChangeHandleContract(submitVals)
                } else {
                    res = api.agentChangeHandleName({
                        id: detailData.id,
                        qId: detailData.qId,
                        newName: vals.edit_newName,
                        newVerifyType: vals.edit_newVerifyType,
                        newVerifyNumber: vals.edit_newVerifyNumber,
                        affix: fileList,
                        remark: vals.edit_remark,
                    })
                }

                res.then(() => {
                    setSaveLoading(false)
                    message.success('操作成功')
                    goList()
                })
                    .catch(() => setSaveLoading(false))
            }
        })
    }

    const goList = () => {
        history.push({
            pathname: '/agent/nameManage'
        })
    }


    return (
        <>
            <div className="pro-detail">
                <Descriptions title="信息变更驳回重提" layout="horizontal " className="pro-detail-title"></Descriptions>

                <Descriptions title="" layout="horizontal" column={2}>
                    <Descriptions.Item label="QID">{detailData.qId}</Descriptions.Item>
                    <Descriptions.Item label="代理商名称">{detailData.oldName}</Descriptions.Item>
                    {detailData.type == 0 && <>
                        <Descriptions.Item label="认证号码类型">{detailData.oldVerifyTypeName}</Descriptions.Item>
                        <Descriptions.Item label="认证号码">{detailData.oldVerifyNumber}</Descriptions.Item>
                    </>}
                </Descriptions>
                <div className="line-box"></div>

                <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
                <div className="promotionProcess">
                    <Steps>
                        {
                            statusList.map((item, index) => {
                                let status = 'wait'
                                if (item.time && item.time.length > 1) {
                                    status = 'process'
                                }
                                return <Step status={status}
                                             key={index}
                                             value={item.time}
                                             title={item.nodeName}
                                             description={
                                                 <>
                                                     {item.persons && <div
                                                         title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                                                     <div title={item.statusName}>{item.statusName}</div>
                                                     <div title={item.time}>{item.time}</div>
                                                 </>
                                             }
                                />
                            })
                        }
                    </Steps>
                </div>
                <div className="line-box"></div>
                <Form onSubmit={formSubmits}>
                    {detailData.type == 1
                        ?
                        <>
                            <Descriptions title="变更后信息" layout="vertical" column={2}></Descriptions>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="合同号">
                                        {getFieldDecorator('contracts', {
                                            initialValue: detailData.type ? detailData.newContract : undefined,
                                            rules: [{required: true, message: '请输入合同号'}]
                                        })(
                                            <Select
                                                mode="multiple"
                                                placeholder="请选择"
                                                notFoundContent={fetching ? <Spin size="small"/> : null}
                                                filterOption={false}
                                                style={{minWidth: '340px'}}
                                                onSearch={onSearchContract}
                                                filterOption={(input, option) => {
                                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }}
                                            >
                                                {contractsList.map(item => (
                                                    <Select.Option
                                                        key={item.contractNo}>{item.contractNo}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Descriptions title="变更前信息" layout="vertical" column={4}>
                                <Descriptions.Item label="QID">{detailData.qId}</Descriptions.Item>
                                <Descriptions.Item label="代理商名称">{detailData.oldName}</Descriptions.Item>
                                <Descriptions.Item label="认证号码类型">{detailData.oldVerifyTypeName}</Descriptions.Item>
                                <Descriptions.Item label="认证号码">{detailData.oldVerifyNumber}</Descriptions.Item>
                            </Descriptions>
                            <div className="line-box"></div>
                            <Descriptions title="变更后信息" layout="vertical" column={2}></Descriptions>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="代理商名称">
                                        {getFieldDecorator('edit_newName', {
                                            initialValue: detailData.newName,
                                            rules: [{required: true, message: '请输入代理商名称'}]
                                        })(<Input
                                            placeholder="请输入"/>)}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="认证号码类型">
                                        {getFieldDecorator('edit_newVerifyType', {
                                            initialValue: detailData.newVerifyType,
                                            rules: [{required: true, message: '请输入认证号码类型'}]
                                        })(
                                            <Select placeholder="请选择" style={{width: 177}}>
                                                {verifyTypeList.map(item => <Option key={item.id}
                                                                                    value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="认证号码">
                                        {getFieldDecorator('edit_newVerifyNumber', {
                                            initialValue: detailData.newVerifyNumber,
                                            rules: [{required: true, message: '请输入认证号码'}]
                                        })(<Input
                                            placeholder="请输入"/>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="line-box"></div>

                            <Descriptions title="" layout="vertical">
                                <Descriptions.Item label="附件">
                                    <div className="dragger-box" ref={draggerRef}>
                                        <Dragger
                                            {...uploadFiles}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox"/>
                                            </p>
                                            <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                            <p className="ant-upload-hint">
                                                支持扩展名：.rar、zip、doc、docx、pdf、jpg, .png, .jpeg, .bmp 单个文件大小不超过5M，不超过5个附件。
                                            </p>
                                        </Dragger>
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                            <div className="line-box"></div>

                            <Row gutter={24}>
                                <Col span={9}>
                                    <Form.Item label="备注">
                                        {getFieldDecorator('edit_remark', {initialValue: detailData.remark})(
                                            <Input.TextArea
                                                placeholder="请输入" rows={6}/>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                    <div className="line-box"></div>

                    <Descriptions
                        title="审批记录"
                        layout="horizontal"
                        className="pro-detail-smailtitle"
                    >
                    </Descriptions>
                    <Table
                        dataSource={logConList}
                        rowKey="id"
                        pagination={false}
                    >
                        <Column title="申请人/审批人" dataIndex="userInfo.staffName"/>
                        <Column title="审批时间" dataIndex="addTime"/>
                        <Column title="状态" dataIndex="status"/>
                        <Column title="备注" dataIndex="chargeMsg"/>
                    </Table>

                    <Descriptions title="" layout="horizontal">
                        <Descriptions.Item label="当前状态">
                            <Tag color="#2db7f5">{detailData.auditStatusName}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                    <BtnGroup onCancel={() => goList()} loading={saveLoading}/>
                </Form>
            </div>
        </>
    )
}

export default Form.create()(AgentNameEdit)