/**
 * 模块名称: 到款列表
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import {
    Form,
    Modal, 
    Select
} from 'antd'

const Option = Select.Option
const FormItem = Form.Item


const Fenpei = (props) => {

    const form = props.form;
    const { getFieldDecorator } = form
    const { persons, visible, setVisible} = props

    const [submitLoading, ] = useState(false)
    const [personsOption, setPersonsOption] = useState([])

    useEffect(() => {

        const personsOption = persons.map((item) => (
            <Option key={item.id}>{item.userName}</Option>
        ))
        setPersonsOption(personsOption)
    }, [persons])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }


    const onSubmit = (e) => {
        e.preventDefault()
    }

    const onOk = () => {
        setVisible(false)
    }

    const onCancel = () => {
        setVisible(false)
    }

    return (
        <Modal
            title="分配负责人"
            visible={visible}
            onOk={onOk}
            confirmLoading={submitLoading}
            onCancel={onCancel}
            width={600}
            maskClosable={true}
        >
            <Form {...formItemLayout} onSubmit={onSubmit}>
                <FormItem label="代理商负责人" >
                    {getFieldDecorator('persons')(
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="请选择"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {personsOption}
                        </Select>
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}

export default Form.create()(Fenpei)