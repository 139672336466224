/**
 * 模块名称: 代理商转移
 * @author yue
 */

import React from 'react'
import {Route, Switch} from 'react-router-dom'
import AgentTransferPage from './AgentTransferPage'
import AddAgentTransfer from './module/AddAgentTransfer'
import AgentTransferDetail from './module/AgentTransferDetail'
import NoMatch from '@/pages/404'
import './assets/style.scss'

const AgentTransfer = ({match, history}) => {

    return (
        <Switch>
            <Route path={match.path + '/add'} exact component={AddAgentTransfer}/>
            <Route path={match.path + '/detail'} exact component={AgentTransferDetail}/>
            <Route component={AgentTransferPage}/>
        </Switch>
    )
}

export default AgentTransfer