/**
 * 模块名称: 代理商名称变更列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Drawer,
  Spin,
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import moment from 'moment'
import api from '@/api'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1

let filter = {
  corpId: '',
  qId: '',
  name: '',
  status: '',
  creator: '',
}

let params = {}

const AgentNameList = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [contractList, setContractList] = useState([])
  const [contractCount, setContractCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [approvalStatusAll, setApprovalStatusAll] = useState([])
  const [expand, setExpand] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      corpId: '',
      qId: '',
      name: '',
      status: '',
      creator: '',
    }
    filter.sTime = ''
    filter.eTime = ''
    getList()
    onsetApprovalStatus()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.agentChangeGetList({ ...filter, limit: pageSize, page: currentPage })
    .then(data => {
      setLoading(false)
      for(var i = 0; i < data.list.length; i++){
        //data.list[i].delFlag = true
        //data.list[i].editFlag = true
      }
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  // 审批状态
  const onsetApprovalStatus = () => {
    api.getCommonWxTab({ dataType: '2', pageType: '2' }).then(data => {
      setApprovalStatusAll(data.list)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        filter = { ...filter, ...vals }
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter = {
      corpId: filter.corpId,
      qId: '',
      name: '',
      status: '',
      creator: '',
    }
    filter.sTime = ''
    filter.eTime = ''
    setStatusVal(undefined)
    setStatusValName(undefined)
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      corpId: data.corpId ? data.corpId : undefined,
      qId: data.qId ? data.qId : undefined,
      name: data.name ? data.name : undefined,
      status: data.status ? data.status : undefined,
      creator: data.creator ? data.creator : undefined,
      sTime: data.sTime ? data.sTime : undefined,
      eTime: data.eTime ? data.eTime : undefined,
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  //编辑
  const onEdit = (date) => {
    history.push('/agent/nameManage/edit?id='+date.id)
  }

  //查看合同
  const onView = (date) => {
    var contract = []
    if(date.oldContract){
      contract.push(date.oldContract)
    }
    if(date.newContract){
      contract.push(date.newContract)
    }
    contract = contract.join(',')

    api.agentGetContractList({limit: global.paramsLimit, page: 1, contract: date.newContract })
    .then(data => {
      setContractList(data.list)
      setContractCount(data.count)
      setVisibleEdit(true)
    })
  }

  const onDelete = (id) => {
    api.agentChangeDel({
      id: id
    }).then(res => {
      message.success('删除成功')
      getList()
    })
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.sTime = ''
      filter.eTime = ''
    } else {
      filter.sTime = moment(data[0]).format('YYYY-MM-DD')
      filter.eTime = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="QID" labelCol={{span: 6}}>
                  {getFieldDecorator('qId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="代理商名称" labelCol={{span: 8}}>
                  {getFieldDecorator('name', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="状态" labelCol={{span: 8}}>
                  {getFieldDecorator('status')(
                    <Select placeholder="请选择">
                      {approvalStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={9} className={`search-btns ${expand ? '' : 'hide'}`}>
                <FormItem label="最后处理时间">
                  {getFieldDecorator('Time', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
              <Col span={6} className={`search-btns ${expand ? '' : 'hide'}`}>
                <FormItem label="申请人" labelCol={{span: 6}}>
                  {getFieldDecorator('creator', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {
                  filterContent.map((item, index) =>
                   <Option value={index} key={index}>
                     <div className="closeStyBtn">
                       {item.name}
                       {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                         e.stopPropagation()
                         onSearchDelete(item.id)
                       }} /></span> : null}
                     </div>
                   </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" style={{marginLeft:8, marginRight:8}} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>

          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert className="count-alert" message={
        <>
          <span>总共{count}条数据</span>
        </>
      }  type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 1400}}
        onChange={onChangeTable}
      >
        <Column title="序号" dataIndex="number" />
        <Column title="QID" dataIndex="qId" />
        <Column title="代理商名称" dataIndex="oldName" width={200}  render={(text, record) =>
          <>
            {
              <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        }  />
        <Column title="变更类型" dataIndex="typeName" />
        <Column title="代理商名称（变更后）" dataIndex="newName" width={200} />
        <Column title="合同" dataIndex="newContract"  render={(text, record) =>
          <>
            {
              record.type == 0 ? '' : <a onClick={() => onView(record)}>查看</a>
            }
          </>
        }/>
        <Column title="状态" dataIndex="auditStatusName"  />
        <Column title="最后处理时间" dataIndex="updateTime" width={160} />
        <Column title="申请人" dataIndex="creatorName" />
        <Column title="操作" key="set" render={(text, record) => (
            <>
                {text.editFlag && <Icon className="operate-icon" type="edit" onClick={() => onEdit(record)
                }/>}
                {' '}
                {text.delFlag &&
                <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                    <Icon className="operate-icon" type="delete"/>
                </Popconfirm>}
            </>
        )}/>
      </Table>

      <Drawer
        title='合同明细记录表'
        width={1200}
        onClose={() => setVisibleEdit(false)}
        visible={visibleEdit}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>

          <Table
          dataSource={contractList}
          rowKey="id"
          className="table-page"
          loading={loading}
          pagination={false}
          scroll={{x: 1200}}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="合同号" dataIndex="contractNo" />
          <Column title="关联主体" dataIndex="accountName" />
          <Column title="项目" dataIndex="projectNames" />
          <Column title="产品" dataIndex="productNames" />
          <Column title="合同领取人" dataIndex="receiver" />
          <Column title="负责人" dataIndex="leadCadreName" />
          <Column title="合同期限" dataIndex="beginTime" render={(text, record) =>
          <>
            {record.beginTime} ~ {record.endTime}
          </>
        } />
        </Table>
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(AgentNameList)