/**
 * 模块名称: 代理商新增管理
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
	Table,
	Form,
	Alert,
	Button,
	Input,
	Select,
	message,
	Icon,
	Row,
	Col,
	Modal,
	Drawer,
	Popconfirm,
	Switch,
	DatePicker
} from 'antd'

import { parseSearch } from '@/utils'
import moment from 'moment'
import CorpFilter from '@/components/CorpFilter'
import Auth from '@/components/AuthMiddleware'
import Export from '@/components/Export'
import { Link } from "react-router-dom"
import Fenpei from "./components/fenpei"
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
	corpId: '',
	qId: '',
	name: '',
	contract: '',
	auditStatus: '',
	updateStartTime: '',
	updateEndTime: ''
}

let selectedIds = ''

let params = {}

const AgentNew = (props) => {
	const { match, location, history } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form

	const productsRef = useRef()

	const [loading, setLoading] = useState(false)
	const [authList, setAuthList] = useState([])
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [expand, setExpand] = useState(false)
	const search = parseSearch(location.search)
	const [statusVal, setStatusVal] = useState(undefined)
	const [filterContent, setFilterContent] = useState([])
	const [filterInfoValue, setFilterInfoValue] = useState('')
	const [visible, setVisible] = useState(false)
	const [checkedIdArr, setCheckedIdArr] = useState([])
	const [hetongVsible, setHetongVsible] = useState(false)
	const [hetongList, setHetongList] = useState([])
	const [fenPeiVisible, setFenPeiVisible] = useState(false)
	const [persons,] = useState([])
	const [statusValName, setStatusValName] = useState(undefined)
	const [flag, setFlag] = useState(false)
	const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
	const [showExport, setShowExport] = useState(false)//导出
	const [approvalStatus, setApprovalStatus] = useState([])//审批状态

	currentPage = search.page ? +search.page : currentPage
	pageSize = search.pageSize ? +search.pageSize : pageSize

	useEffect(() => {
		filter = {
			corpId: '',
			qId: '',
			name: '',
			contract: '',
			auditStatus: '',
			updateStartTime: '',
			updateEndTime: ''
		}
		shouldMe = null
		apiOperateTimer = true
		getList()
		api.getPageAuth().then(list => setAuthList(list))
		api.getAuditStatusList({type: '14'}).then(data => {
			console.log(data)
      setApprovalStatus(data)
    })
	}, [])

	const getList = () => {
		if (apiOperateTimer) {
			setLoading(true)
			if (shouldMe) {
				filter.shouldMe = shouldMe
			} else {
				for (let i in filter) {
					if (i == 'shouldMe') delete filter[i]
				}
			}
			api.agentVerifyGetList({ ...filter, limit: pageSize, page: currentPage }).then(data => {
				setLoading(false)
				setList(data.list)
				setCount(data.count)
			}).catch(() => setLoading(false))
			apiOperateTimer = false
			setShouldMeDis(false)
			let timer = setTimeout(() => {
				apiOperateTimer = true
				setShouldMeDis(true)
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.info('请不要频繁操作')
		}
	}

	const onSetShouldMe = e => {
		if (e == true) {
			shouldMe = 1
		} else {
			shouldMe = null
		}
		getList()
	}

	// 查询
	const onSearch = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				if (vals.updateTime) {
					filter.updateStartTime = moment(vals.updateTime[0]).format('YYYY-MM-DD')
					filter.updateEndTime = moment(vals.updateTime[1]).format('YYYY-MM-DD')
				} else {
					filter.updateStartTime = ''
					filter.updateEndTime = ''
				}
				filter.qId = vals.qId
				filter.name = vals.name
				filter.contract = vals.contract
				filter.auditStatus = vals.auditStatus

				history.replace(match.path)
				currentPage = 1
				getList()
			}
		})
	}

	const onChangeTable = (pagination, filters, sorter) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
		getList()
	}

	const onChangeCorp = (data) => {
		filter.corpId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		getList()
	}

	const onToggle = () => {
		setExpand(!expand)
	}

	const onReset = () => {
		resetFields()
		shouldMe = null
		filter.dateStart = ''
		filter.dateEnd = ''
		filter = {
			corpId: filter.corpId,
			qId: '',
			name: '',
			contract: '',
			auditStatus: '',
			updateStartTime: '',
			updateEndTime: ''
		}
		setStatusVal(undefined)
		setStatusValName(undefined)
		currentPage = 1
		history.replace(match.path)
		getList()
	}

	// 弹出框确定
	const HandleOk = () => {
		api.saveSearchStorage({
			name: filterInfoValue,
			key: location.pathname,
			content: params
		}).then(data => {
			setVisible(false)
			message.success('添加成功')
		})
	}

	const HandleCancel = () => {
		setVisible(false) //弹出框内取消
	}

	const FilterInfoChange = (e) => {
		setFilterInfoValue(e.target.value) //弹框input值
	}

	const onChangeGetFilter = (val) => {
		val ? setFlag(true) : setFlag(false)
		// 下拉框搜索内容
		val && api.getSearchStorage({ key: location.pathname }).then(data => {
			setFilterContent(data.list)
		})
	}

	const onChecked = (value, val2) => {
		setStatusVal(value)
		setStatusValName(val2.props.children.props.children[0])
		// let data = JSON.parse(value)
		let data = filterContent[value].content
		let fields = getFieldsValue()
		let params = {};
		delete fields['date']

		for (let i in fields) {
			params[i] = data[i] ? data[i] : undefined
		}
		setFieldsValue(params)
	}

	// 筛选删除
	const onSearchDelete = (id) => {
		api.searchStorageDelete({
			id: id
		}).then(() => {
			api.getSearchStorage({ key: location.pathname }).then(data => {
				setFilterContent(data.list)
			})
		})
	}

	const onSaveFilterTerm = () => {
		validateFields((err, vals) => {
			if (!err) {
				const values = Object.values(vals)
				if (values.some(item => item !== undefined)) {
					params = {}

					for (let k in vals) {//eslint-disable-line
						if (vals[k] !== undefined) {
							params[k] = vals[k]
						}
					}

					setVisible(true)
				} else {
					message.error('当前没有设置筛选条件')
				}
			}
		})
	}

	const updateSelected = (checkedIdArr) => {
		setCheckedIdArr(checkedIdArr)
	}

	const showHetong = (contract) => {
		api.agentGetContractList({ contract })
			.then((res) => {
				setHetongVsible(true)
				setHetongList(res.list)
			})
	}


	const onDelete = (id) => {
		api.agentVerifyDel({
			id: id
		}).then(res => {
			message.success('删除成功')

			const index = checkedIdArr.indexOf(id);
			if (index > -1) {
				checkedIdArr.splice(index, 1);
				updateSelected(checkedIdArr)
			}
			getList()
		})
	}

	//导出
	const onExportFile = () => {
		setShowExport(true)
		if (filter.corpId && filter.corpId.length > 0) {
			if (!Array.isArray(filter.corpId)) {
				filter.corpId = filter.corpId.split(',')
			}
		} else {
			filter.corpId = []
		}
		// Object.assign(filter)
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	return (
		<>
			<CorpFilter onChange={onChangeCorp} />
			<Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
				<div className="search-item-wrap">
					<div className="search-item">
						<Row gutter={24}>
							<Col span={8}>
								<FormItem label="QID" labelCol={{ span: 8 }}>
									{getFieldDecorator('qId', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="代理商名称" labelCol={{ span: 8 }}>
									{getFieldDecorator('name', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="合同号" labelCol={{ span: 8 }}>
									{getFieldDecorator('contract', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={24} className={expand ? '' : 'hide'}>
							<Col span={8}>
								<FormItem label="审批状态" labelCol={{ span: 8 }}>
									{getFieldDecorator('auditStatus')(
										<Select
											placeholder="请选择"
											dropdownMatchSelectWidth={false}
										>
											{
												approvalStatus.map((v, i) => {
													return <Option value={v.id} key={i}>{v.name}</Option>
												})
											}
										</Select>)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="最后处理时间" labelCol={{ span: 8 }}>
									{getFieldDecorator('updateTime')(<RangePicker allowClear={false} />)}
								</FormItem>
							</Col>
						</Row>
					</div>
					<span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle}
						style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
						<span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
					</span>
				</div>
				<div className="search-btns" style={{ paddingBottom: 10 }}>

					<div className={`search-btns ${expand ? '' : 'hide'}`}>
						<h4 className="filter-title">已保存筛选方案：</h4>
						<div className="filter-select">
							<Select
								style={{ display: 'inline-block', width: '160px' }}
								placeholder="请选择"
								value={statusVal}
								onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
								onSelect={onChecked}
							>
								{
									filterContent.map((item, index) =>
										<Option value={index} key={index}>
											<div className="closeStyBtn">
												{item.name}
												{flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
													e.stopPropagation()
													onSearchDelete(item.id)
												}} /></span> : null}
											</div>
										</Option>
									)
								}
							</Select>
						</div>
						<Button className="filter-savebtn" onClick={onSaveFilterTerm}
							style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
					</div>
					<Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
					<Button onClick={onReset}>重置</Button>
					<Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
					<Modal
						title="保存筛选条件"
						visible={visible}
						onOk={HandleOk}
						onCancel={HandleCancel}
					>
						<Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
					</Modal>
				</div>
			</Form>
			<div className="line"></div>
			<div className="white-line"></div>
			<div className="operate-wrap" style={{ marginBottom: '10px' }}>
				<Auth auths={authList} code="export">
					<Button type="primary" style={{ marginLeft: '20px' }} onClick={onExportFile}>导出</Button>
					<Export
						show={showExport}
						onCancel={() => {
							setShowExport(false)
							productsRef.current.changeVal()
						}}
						tplUrl="getFortuneagentverifyChangeExportTpl"
						fieldsUrl="getFortuneagentverifyChangeTplItems"
						saveUrl="saveFortuneagentverifyChangeExportTpls"
						exportUrl="exportFortuneagentverifyExport"
						method="export"
						parame={{ ExportType: 'agentverify.export', id: selectedIds, ...filter }}
						cRef={productsRef}
					/>
				</Auth>
			</div>
			<Alert className="corp-count" message={
				<>
					<span>总共{count}条数据</span>
				</>
			} type="info" showIcon />
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				className="table-page"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				onChange={onChangeTable}
				scroll={{ x: 'max-content' }}
			>
				<Column title="序号" dataIndex="number" />
				<Column title="QID" dataIndex="qId" />
				<Column title="代理商名称" render={
					(record) => {
						return (<Link className="operate-icon" to={`/agent/agentNew/agentNewDetail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`}>{record.name}</Link>)
					}
				} />
				<Column title="认证号码类型" dataIndex="verifyTypeName" />
				<Column title="认证号码" dataIndex="verifyNumber" />
				<Column title="状态" dataIndex="auditStatusName" />
				<Column title="申请人" dataIndex="creatorName" />
				<Column title="最后处理时间" dataIndex="updateTime" />
				<Column title="合同" render={(record) => {
					return (<a className="operate-icon" onClick={() => showHetong(record.contract)}>{"查看"}</a>)

				}} />
				<Column title="操作" render={(record) => {
					if (!record.delFlag) {
						return null
					}

					return (
						<Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认"
							onConfirm={(e) => onDelete(record.id)}>
							<a className="operate-icon" type="delete">删除</a>
						</Popconfirm>
					)
				}} />
			</Table>


			<Drawer
				title="合同明细记录表"
				width={1000}
				onClose={() => setHetongVsible(false)}
				visible={hetongVsible}
			>
				<Table
					dataSource={hetongList}
					rowKey="id"
					className="table-page"
					scroll={{ x: 900 }}
				>
					<Column title="序号" dataIndex="number" />
					<Column title="合同号" dataIndex="contractNo" />
					<Column title="关联主体" dataIndex="accountName" />
					<Column title="项目" dataIndex="projectNames" />
					<Column title="产品" dataIndex="productNames" />
					<Column title="合同领取人" dataIndex="receiver" />
					<Column title="负责人" dataIndex="leadCadreName" />
					<Column title="合同期限" render={(record) => {
						return record.beginTime + ' ~ ' + record.endTime
					}} />
				</Table>
			</Drawer>
			<Fenpei persons={persons} qIds={checkedIdArr} visible={fenPeiVisible} setVisible={setFenPeiVisible} />

		</>
	)
}

export default Form.create()(AgentNew)