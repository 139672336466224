/**
 * 模块名称: 厂商id转移管理列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Switch
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let editId = '' //编辑使用
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  corpId: '',
  relationId: '',
  relationType: '',

  id: '',
  type: '',
  status: '',
  creator: '',
}

let selectedIds = ''
let params = {}

const Manufacturer = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [expand, setExpand] = useState(false)
  const [typeList, setTypeList] = useState([])
  const [statusList, setStatusList] = useState([])
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  currentPage = search.page ? +search.page : 1
  pageSize = search.pageSize ? +search.pageSize : 10

  useEffect(() => {
    filter = {
      corpId: '',
      id: '',
      type: '',
      status: '',
      creator: '',
    }
    filter.sTime = ''
    filter.eTime = ''
    shouldMe = null
    apiOperateTimer = true
    getList()
    getFilterData()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      filter.relationId = search.relationId
      filter.relationType = search.relationType
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.agentManufacturerGetList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        for(var i = 0; i < data.list.length; i++){
          //data.list[i].delFlag = true
          //data.list[i].editFlag = true
        }
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  const getFilterData = () => {
    api.agentManufacturerGetFilterData({}).then(data => {
      setTypeList(data.transferType)
      setStatusList(data.auditStatus)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        filter = { ...filter, ...vals }
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.corpId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    shouldMe = null
    filter.sTime = ''
    filter.eTime = ''
    filter = {
      corpId: filter.corpId,
      id: '',
      type: '',
      status: '',
      creator: '',
    }
    currentPage = 1
    history.replace(match.path)
    setStatusVal(undefined)
    setStatusValName(undefined)
    getList()
  }

  const onShowExportDialog = () => {
    setShowExport(true)
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      corpId: data.corpId ? data.corpId : undefined,
      id: data.id ? data.id : undefined,
      relationId: data.relationId ? data.relationId : undefined,
      relationType: data.relationType,
      type: data.type ? data.type : undefined,
      status: data.status ? data.status : undefined,
      sTime: data.sTime ? data.sTime : undefined,
      eTime: data.eTime ? data.eTime : undefined,
      creator: data.creator ? data.creator : undefined,
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 添加
  const onAdd = () => {
    editId = 0
    history.push('/agent/manufacturer/add')
  }

  //编辑
  const onEdit = (date) => {
    editId = date.id
    history.push('/agent/manufacturer/edit?id='+editId)
  }

  const onDelete = (id) => {
    api.agentManufacturerDel({
      id: id
    }).then(res => {
      message.success('删除成功')
      for(let i = 0; i<selectedIds.length; i++){
        if(selectedIds[i] === id){
          selectedIds.splice(i, 1)
          i--
        }
      }
      getList()
    })
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.sTime = ''
      filter.eTime = ''
    } else {
      filter.sTime = moment(data[0]).format('YYYY-MM-DD')
      filter.eTime = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="厂商ID" labelCol={{span: 8}}>
                  {getFieldDecorator('vendor', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转入类型" labelCol={{span: 8}}>
                  {getFieldDecorator('type')(
                    <Select placeholder="请选择">
                      {typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="状态" labelCol={{span: 8}}>
                  {getFieldDecorator('status')(
                    <Select placeholder="请选择">
                      {statusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={8}>
                <FormItem label="最后处理时间">
                  {getFieldDecorator('updateTime', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请人" labelCol={{span: 8}}>
                  {getFieldDecorator('creator', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {
                  filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" style={{marginLeft:8, marginRight:8}} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="operate-wrap">
        <Auth auths={authList} code="operate">
          <Button type="primary" icon="plus" onClick={onAdd}>添加</Button>
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        </Auth>
      </div>
      <Alert className="count-alert" message={
        <>
          <span>总共{count}条数据</span>
        </>
      }  type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 1900}}
        onChange={onChangeTable}
      >
        <Column title="序号" dataIndex="number" width={80} fixed={'left'} />
        <Column title="ID" dataIndex="id" width={80} fixed={'left'} render={(text, record) =>
          <>
          {
            <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
          }
        </>
        } />
        <Column title="转入类型" dataIndex="typeName" />
        <Column title="OAID" dataIndex="clientId" />
        <Column title="客户名称" dataIndex="clientName" />
        <Column title="QID(转入)" dataIndex="transferInQId" />
        <Column title="代理商(转入)" dataIndex="transferInAgentName" />
        <Column title="关联主体(转入)" dataIndex="accountName" />
        <Column title="最后处理时间" dataIndex="updateTime" />
        <Column title="状态" dataIndex="auditStatusName" />
        <Column title="申请人" dataIndex="creatorName" />
        <Column title="负责人" dataIndex="transferInAgentPrincipalName" />
        <Column title="所属销售" dataIndex="transferInClientSaleName" />
        <Column title="所属客服" dataIndex="transferInClientServiceName" />
        <Column title="操作" width={100} fixed={'right'} key="set" render={(text, record) => (
          <>
            {text.editFlag && <Icon className="operate-icon" type="edit" style={{ color: '#168FFF' }} onClick={() => onEdit(record)
            }/>}
            {' '}
            {text.delFlag &&
            <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Icon className="operate-icon" style={{ color: 'red' }} type="delete"/>
            </Popconfirm>}
          </>
        )}/>
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getManufacturerExportTpl"
        fieldsUrl="getManufacturerTplItems"
        saveUrl="saveManufacturerTpl"
        exportUrl="exportManufacturer"
        method="export"
        parame={{ ExportTyp: 'manufacturer', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Manufacturer)