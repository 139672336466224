/**
 * 模块名称: 代理商转移 => 代理商转移详情
 * @author yue
 */
import React, {useEffect, useState} from 'react'
import api from '@/api'
import {Button, Descriptions, Icon, Input, message, Modal, Spin, Steps, Tag} from 'antd'
import {parseSearch} from '@/utils'
import PublicAgentTransfer from './PublicAgentTransfer'
import CryptoJS from 'crypto-js'

const {Step} = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

//审批记录
export const auditRecordRender = (auditRecord, statusName) => {
    return (
        <div>
            <div className="blank-line"></div>
            <div className="record-list">
                <div className="record-list-title">审批记录</div>
                <Descriptions layout="vertical" className="pro-detail-title" column={4}
                              style={{borderBottom: "none", marginBottom: "-30px"}}>
                    <dItem label="姓名"></dItem>
                    <dItem label="时间"></dItem>
                    <dItem label="状态"></dItem>
                    <dItem label="审批意见"></dItem>
                </Descriptions>
                {
                    auditRecord.map((item, index) => {
                        return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index}
                                             style={{borderBottom: "none", marginBottom: "-10px"}}>
                            <dItem label="">{item.userInfo.staffName}</dItem>
                            <dItem label="">{item.addTime}</dItem>
                            <dItem label="">{item.status}</dItem>
                            <dItem label="">{item.chargeMsg}</dItem>
                        </Descriptions>
                    })
                }
            </div>
            <div className="line-box"></div>
            <Descriptions title="" layout="horizontal">
                <Descriptions.Item label="当前状态">
                    <Tag color="#2db7f5">{statusName}</Tag>
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

const AgentTransferDetail = (props) => {

    const {history, location} = props

    const [detail, setDetail] = useState({})
    const [flow, setFlow] = useState([])
    const [remark, setRemark] = useState([])
    const [affixList, setAffixList] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [pageId, setPageId] = useState('')
    const [auditRecord, setAuditRecord] = useState([])
    const [auditStatusName, setAuditStatusName] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')

    //操作权限
    const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
    const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
    const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
    const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
    const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

    //get参数
    const search = parseSearch(location.search);
    //列表参数
    const [listParams, setListParams] = useState(undefined)

    useEffect(() => {
        let plaintext = search.sign
        let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
        if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
            areaTxt = ''
            result = ''
            if (search.id) {
                setPageId(search.id)
                getAgentTransferRow(search.id)
            } else {
                goList();
            }
        } else {
            message.error('没有本页访问权限')
            setTimeout(() => {
            history.go(-2)
            }, 1000)
        }
    }, [])

    //跳转到列表页
    const goList = () => {
        history.push({
            pathname: '/agent/agentTransfer'
        })
    }

    //获取代理商转移详情
    const getAgentTransferRow = (id) => {
        api.getAgentTransferDetail({id: id, type: 0}).then(res => {
            if (search.msgId) {
                api.setMessageReaded({ids: [search.msgId]})
            }

            //设置转移代理商QID
            setListParams({qId: res.qId, transferOutId: res.transferOutId, snapshotFlag: res.snapshotFlag, snapshot: res.snapshot})

            if (JSON.stringify(res) !== "[]") {
                let data = Object.assign({}, res)
                if (data.status === 0) data.current = 0
                if (data.status === 1) data.current = 1
                if (data.status === 2) data.current = 2
                if (data.status === 3) data.current = 3
                setDetail(data)

                let auditFlow = data.audit_flow
                auditFlow.forEach((item, index, self) => {
                    if (item.persons && item.persons.length) {
                        self[index].persons = item.persons.join('，')
                    }
                })
                setFlow(auditFlow)
                setAffixList(data.affix)
                setAuditRecord(data.audit_log)
                setAuditStatusName(data.auditStatusName)
                if (data.remark.length) {
                    setRemark(data.remark)
                } else {
                    setRemark('无')
                }
                if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
                    setAuditAuth(true)
                    setHasChargerPower(data.auditAuth.hasChargerPower)
                    setReplenish(data.auditAuth.replenish)
                    setReturnUp(data.auditAuth.return_up)
                    setRejectMsgRequire(data.auditAuth.reject_msg_require)
                } else {
                    setAuditAuth(false)
                    setHasChargerPower(false)
                    setReplenish(false)
                    setReturnUp(false)
                    setRejectMsgRequire(false)
                }
            } else {
                setDetail({})
                setFlow([])
                setAffixList([])
                setAuditRecord([])
            }
            setLoading(false)
        })
    }
    //点击4个审批的按钮
    const audit = (no) => {
        areaTxt = ''
        result = no
        if (no == 0) {//eslint-disable-line
            setResPlaceholder('请输入审批意见')
            setModalVisible(true)
        } else if (no == 1) {//eslint-disable-line
            //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:23
            setResPlaceholder('请输入审批意见')
            setModalVisible(true)
        } else {
            setLoading(true)
            api.agentTransferAudit({
                id: pageId,
                type: result,
                remark: areaTxt
            }).then(res => {
                //转上级或者补充资料 返回列表页
                let timer = setTimeout(() => {
                    goList()
                    clearTimeout(timer)
                }, 1000)
            })
        }
    }
    const handleOk = () => {
        if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
            message.warning('请输入至少1个字符')
            return
        }
        let params = {
            id: pageId,
            type: result,
            remark: areaTxt
        }
        setModalVisible(false)
        setLoading(true)
        api.agentTransferAudit(params).then(res => {
            message.success('审批成功')
            getAgentTransferRow(pageId)
        })
    }
    //取消弹窗
    const handleCancel = () => {
        setModalVisible(false)
    }

    //同意的样式
    const agreeNode = () => {
        return result === 0 ? <>
            同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
        </> : <>
            不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96"/>
        </>
    }

    //审批意见
    const getAreaTxt = (e) => {
        areaTxt = e.target.value.trim()
    }

    return (
        <Spin spinning={loading}>
            <div className="add-newask-detail">
                <Descriptions title="基础详情页" layout="horizontal" className="pro-detail-title"
                              style={{borderBottom: 'none'}}>
                    {detail.transferOutName && <dItem label="负责人（转出）">{detail.transferOutName}</dItem>}
                </Descriptions>
                <div className="blank-line"></div>
                <div className="step-wrap">
                    <div className="record-list-title">当前审批进度</div>
                    <Steps current={detail.current}>
                        {
                            flow.map((item, index, self) => {
                                let status = 'wait'
                                if (item.time && item.time.length > 1) {
                                    status = 'process'
                                }
                                return <Step status={status} title={item.nodeName} key={index} description={
                                    <>
                                        {item.persons && item.persons && <div title={item.persons}>{item.persons}</div>}
                                        <div title={item.statusName}>{item.statusName}</div>
                                        <div title={item.time}>{item.time}</div>
                                    </>
                                }/>
                            })
                        }
                    </Steps>
                </div>
                <div className="blank-line"></div>
                <div className="step-wrap">
                    <PublicAgentTransfer listParams={listParams}/>
                </div>
                <Descriptions title="转入信息"
                              layout="horizontal"
                              className="pro-detail-smailtitle"
                >
                    <dItem label="负责人（转入）">{detail.transferInName}</dItem>
                    <dItem label="直属上级">{detail.transferInLeadName}</dItem>
                    <dItem label="所属部门">{detail.transferInDepartmentName}</dItem>
                </Descriptions>
                <Descriptions title="附件"
                              layout="horizontal"
                              className="pro-detail-smailtitle"
                >
                    <div className="content">
                        {
                            affixList.length ? affixList.map((item, index) => {
                                return <div key={index}><a href={item.url} target="_blank"
                                                           rel="noopener noreferrer">{item.name}</a></div>
                            }) : '无'
                        }
                    </div>
                </Descriptions>
                <Descriptions title="备注"
                              layout="horizontal"
                              className="pro-detail-smailtitle"
                >
                    <div className="content">
                        {remark}
                    </div>
                </Descriptions>
                {auditRecordRender(auditRecord, auditStatusName)}
                {
                    auditAuth && <div className="btns">
                        {/*{replenish && <Button onClick={() => audit(3)} className="orange-btn">补充资料</Button>}*/}
                        {returnUp && <Button onClick={() => audit(2)} className="orange-btn">转上级</Button>}
                        {hasChargerPower && <Button onClick={() => audit(1)}>驳回</Button>}
                        {hasChargerPower && <Button onClick={() => audit(0)} type="primary">通过</Button>}
                    </div>
                }
                <Modal
                    title={agreeNode()}
                    visible={modalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                >
                    <div className="officework-modal-content">
                        <span className="label">意见：</span><Input.TextArea className="content-area"
                                                                          placeholder={resPlaceholder}
                                                                          onChange={getAreaTxt} rows={4}
                                                                          style={{width: "100%"}} maxLength={100}/>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default AgentTransferDetail;
