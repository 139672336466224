/**
 * 模块名称: 厂商id转移管理申请页
 * @author xuxiaobo@372163.com
 */

import React, { useEffect, useRef, useState } from 'react'
import { Col, Descriptions, Form, Icon, Input, message, Radio, Row, Select, Steps, Table, Tag, Upload } from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table

const Option = Select.Option
const { Dragger } = Upload;
let pageSize = 10
let currentPage = 1
let selectedIds = []
let type = 0

let transferInQId = 0
let transferInAgentName = ''
let transferInAgentPrincipalId = 0
let transferInAgentPrincipalName = ''
let transferInClientSaleId = 0
let transferInClientSaleName = ''
let transferInClientServiceId = 0
let transferInClientServiceName = ''
let clientId = ''

const ManufacturerAdd = (props) => {
  const { location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form

  const search = parseSearch(location.search)
  const [manufacturerData, setManufacturerData] = useState({})
  const [count, setCount] = useState(0)
  const [statusList, setStatusList] = useState([])
  const [transferFirmList, setTransferFirmList] = useState([])
  const [fundAccountList, setFundAccountList] = useState([])
  const [detailDataId, setdetailDataId] = useState(0)
  const [accountId, setAccountId] = useState('')
  const [accountName, setAccountName] = useState('')
  const [drawerTitle, setDrawerTitle] = useState('代理商厂商ID转移申请')
  const [saveLoading, setSaveLoading] = useState(false)
  const [processShow, setProcessShow] = useState(false)
  const [logConList, setLogConList] = useState([])
  const [fileList, setFileList] = useState([])
  const [relationPro, setRelationPro] = useState('')
  const [showRelationPro, setShowRelationPro] = useState(false)
  const draggerRef = useRef(null)
  const [qids, setQids] = useState([])
  const [principalIds, setPrincipalIds] = useState([])
  const [saleIds, setSaleIds] = useState([])
  const [serviceIds, setServiceIds] = useState([])
  const [dailishow, setDailishow] = useState(false)
  const [kaifaFlag, setKaifaFlag] = useState(true)
  const [xiaoshouFlag, setXiaoshouFlag] = useState(true)
  const [kefuFlag, setKefuFlag] = useState(true)
  const [qIdFlag, setQIdFlag] = useState(true)
  const [transferInClientList, setTransferInClientList] = useState([])
  const [transferInClientName, setTransferInClientName] = useState('')
  const [qid, setQid] = useState('')
  const [clientIds, setClientIds] = useState('')

  useEffect(() => {
    // console.log(search)
    if (search.id > 0) {
      setDrawerTitle('代理商厂商ID转移驳回重提')
      setProcessShow(true)

      api.agentManufacturerGetDetail({ id: search.id, type: 0 }, true).then(data => {
        // 代理商（转入）客户名称
        let datas = data
        api.getAgentFirmTransferGetCustom({ type: data.type, userType: 0, name: data.transferInClientName, qId: data.transferInQId }).then(res => {
          // console.log(res)
          setTransferInClientList(res.list)
          setFieldsValue({
            transferInClientId: datas.transferInClientId
          })
          setTransferInClientName(datas.transferInClientName)
        })

        // 返显示代理商
        if (data.type == 1) {
          api.agentManufacturerGetTransferInUser({
            clientId: data.transferInClientId, type: 0, qId: data.transferInQId
          }).then(tmps => {
            for (var i = 0; i < tmps.length; i++) {
              tmps[i].name = tmps[i].staffName
            }
            setPrincipalIds(tmps)
            setFieldsValue({
              edit_transferInAgentPrincipalId: data.transferInAgentPrincipalId
            })
          }).catch(err => console.log(err))
        } else {
          setClientIds(data.transferInClientId)
          //客服
          api.agentManufacturerGetTransferInUser({
            clientId: data.transferInClientId, type: 2
          }).then(tmps => {
            for (var i = 0; i < tmps.length; i++) {
              tmps[i].name = tmps[i].staffName
            }
            setServiceIds(tmps)
          }).catch()
          //销售
          api.agentManufacturerGetTransferInUser({
            clientId: data.transferInClientId, type: 1
          }).then(tmps => {
            for (var i = 0; i < tmps.length; i++) {
              tmps[i].name = tmps[i].staffName
            }
            setSaleIds(tmps)
          }).catch()
          // 返显直销代理商
          api.agentManufacturerGetTransferInUser({
            clientId: data.transferInClientId, type: 3
          }).then(tmps => {
            for (var i = 0; i < tmps.length; i++) {
              tmps[i].name = tmps[i].staffName
            }
            setPrincipalIds(tmps)
            setFieldsValue({
              edit_transferInAgentPrincipalId: data.transferInAgentPrincipalId
            })
          }).catch()
        }

        setManufacturerData(data)
        setdetailDataId(data.id)
        clientId = data.clientId
        type = data.type
        onChooseRadio(data.type, data)

        setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
        setLogConList(data.audit_log) // 审批日志

        data.affix.forEach((item, index) => {
          fileList.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList(data.affix.map((item, index) => ({ ...item, uid: index })))

        api.agentManufacturerGetTransferFirmList({
          oaId: data.clientId,
          firmInfoId: data.firmInfoIds,
          page: currentPage,
          limit: pageSize
        }, true).then(tmps => {
          setCount(tmps.count)
          setTransferFirmList(tmps.list)
        })

        setAccountId(data.account)
        setAccountName(data.accountName)

        transferInQId = data.transferInQId
        transferInAgentName = data.transferInAgentName

        var staffIds = []
        if (data.transferInAgentPrincipalId) {
          transferInAgentPrincipalId = data.transferInAgentPrincipalId
          transferInAgentPrincipalName = data.transferInAgentPrincipalName
          staffIds.push(data.transferInAgentPrincipalId)
        }
        if (data.transferInClientSaleId) {
          transferInClientSaleId = data.transferInClientSaleId
          transferInClientSaleName = data.transferInClientSaleName
          staffIds.push(data.transferInClientSaleId)
        }
        if (data.transferInClientServiceId) {
          transferInClientServiceId = data.transferInClientServiceId
          transferInClientServiceName = data.transferInClientServiceName
          staffIds.push(data.transferInClientServiceId)
        }
        staffIds = staffIds.join(',')

        api.agentManufacturerGetTransferInAccount({
          agentId: data.transferInQId,
          clientId: data.clientId,
          type: data.type,
          // staffIds: staffIds
          saleId: data.transferInClientSaleId,
          serviceId: data.transferInClientServiceId,
          agentStaffId: data.transferInAgentPrincipalId,
        }, true).then(tmps => {
          setFundAccountList(tmps)
        })
      })
    } else {
      setDrawerTitle('代理商厂商ID转移申请')
      setProcessShow(false)

      //relationType=0直销,1代理商
      api.agentManufacturerGetBaseData({ clientId: search.clientId, qId: search.qId }, true).then(data => {
        data.type = 1
        data.agentName = data.aName
        data.clientName = data.cName
        data.clientId = search.clientId
        data.qId = search.qId
        data.firmInfoIds = search.ids.split(',')
        data.transferInAgentName = ''
        data.transferInAgentPrincipalName = ''
        data.transferInClientSaleName = ''
        data.transferInClientServiceName = ''
        data.transferInAgentPrincipalId = undefined
        data.transferInClientSaleId = undefined
        data.transferInClientServiceId = undefined
        data.account = 0
        data.remark = ''
        data.auditStatusName = ''

        clientId = data.clientId
        type = data.type

        setManufacturerData(data)
        setdetailDataId(0)
        onChooseRadio(data.type, data)

        api.agentManufacturerGetTransferFirmList({
          oaId: data.clientId,
          firmInfoId: search.ids,
          page: currentPage,
          limit: pageSize
        }, true).then(tmps => {
          setCount(tmps.count)
          setTransferFirmList(tmps.list)
        })
      })
    }
  }, [])

  //radio选择
  const onChooseRadio = (val, data) => {
    transferInQId = 0
    type = val
    setFieldsValue({
      edit_qId: undefined,
      transferInClientId: undefined,
      edit_transferInAgentPrincipalId: undefined,
      // edit_transferInClientSaleId: undefined,
      // edit_transferInClientServiceId: undefined,
    })
    // transferInClientSaleId=0
    // transferInClientServiceId=0
    setQid('')
    setQids([])
    setTransferInClientList([])
    setPrincipalIds([])
    setServiceIds([])
    setSaleIds([])
    setFundAccountList([])
    transferInClientSaleId = 0
    transferInClientServiceId = 0
    if (val == "1") { //代理商
      setDailishow(true)
      setQIdFlag(false)
      setKaifaFlag(false)
      qIdSelect(data.transferInAgentName, data)
      // principalIdSelect(data.transferInAgentPrincipalName, data)
    } else {
      setDailishow(false)
      setXiaoshouFlag(false)
      setKefuFlag(false)

      // saleIdSelect(data.transferInClientSaleName, data)
      // serviceIdSelect(data.transferInClientServiceName, data)
    }
  }

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      selectedIds = selectedIds ? selectedIds : []
      let saveData = {
        id: detailDataId,
        firmInfoIds: manufacturerData.firmInfoIds,
        type: type,
        qId: manufacturerData.qId,
        agentName: manufacturerData.agentName,
        clientId: manufacturerData.clientId,
        clientName: manufacturerData.clientName,
        accountName: accountName,
        account: accountId,
        transferInQId: transferInQId,
        transferInAgentName: transferInAgentName,
        transferInAgentPrincipalId: transferInAgentPrincipalId,
        transferInAgentPrincipalName: transferInAgentPrincipalName,
        transferInClientSaleId: transferInClientSaleId,
        transferInClientSaleName: transferInClientSaleName,
        transferInClientServiceId: transferInClientServiceId,
        transferInClientServiceName: transferInClientServiceName,
        affix: fileList,
        remark: vals.edit_remark,
        transferInClientId: vals.transferInClientId,
        transferInClientName: transferInClientName
      }

      if (!err) {
        if (saveData.account == '' || saveData.accountName == '') {
          return message.warning('无转入主体')
        }
        setSaveLoading(true)
        api.agentManufacturerHandle(saveData).then(() => {
          setSaveLoading(false)
          message.success('操作成功')
          history.push({
            pathname: '/agent/manufacturer'
          })
        }).catch(() => setSaveLoading(false))
      }
    })

    return false;
  }

  const selectZhuTi = (id) => {
    var name = ''
    for (var i = 0; i < fundAccountList.length; i++) {
      if (fundAccountList[i].id == id) {
        name = fundAccountList[i].name
      }
    }

    setAccountId(id)
    setAccountName(name)
    setFundAccountList(fundAccountList.map(item => {
      let showYE = false;
      if (item.id === id) {
        setRelationPro(item.projectRange)
        setShowRelationPro(true)
        showYE = true;
      }
      return { ...item, showYE }
    }))
  }

  const MyRadio = (props) => {
    const [showYE, setShowYE] = useState(false);
    const { data } = props
    useEffect(() => {
      setShowYE(data.showYE !== undefined ? data.showYE : false)
    }, [data])
    return <>
      <Radio.Button className="radio-container" value={data.id}>
        <img className="mainNameBodySelLogo" src={data.logo} alt="" />{data.name}
        <Icon className="check" type="check" />
      </Radio.Button>{showYE ? <span>&nbsp;</span> : <span>&nbsp;</span>}
    </>
  }

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png, .jpeg, .bmp',
    allowSizeType: 2
  })

  //选中qid
  const onSelectQId = (id) => {
    // console.log(id)
    setFieldsValue({
      edit_transferInAgentPrincipalId: undefined
    })
    setPrincipalIds([])
    setFundAccountList([])
    setQid(id)
    var name = ''
    for (var i = 0; i < qids.length; i++) {
      if (qids[i].id == id) {
        name = qids[i].name
      }
    }
    transferInQId = id
    transferInAgentName = name

    // principalIdSelect('')
    if (transferInClientList.length > 0) {
      setFieldsValue({
        transferInClientId : undefined
      })
      setTransferInClientList([])
    }

    if (transferInAgentPrincipalId) {
      api.agentManufacturerGetTransferInAccount({
        agentId: transferInQId,
        clientId: clientId,
        type: type,
        saleId: '',
        serviceId: '',
        agentStaffId: transferInAgentPrincipalId
        // staffIds: transferInAgentPrincipalId
      }, true).then(tmps => {
        setFundAccountList(tmps)
      })
    }
  }

  // 筛选qid
  const qIdSelect = (value, data) => {
    value = value ? value : (manufacturerData ? manufacturerData.transferInAgentName : (data.transferInAgentName ? data.transferInAgentName : data.agentName))
    var clientId = manufacturerData.clientId ? manufacturerData.clientId : data.clientId
    var qId = manufacturerData.qId ? manufacturerData.qId : data.qId

    if (value && value.trim()) {
      api.getAgentFirmTransferGetCustom({
        type: 1,
        userType: 1,
        name: value,
        qId: qId
      }).then(tmps => {
        if (tmps.list.length >= 50) {
          tmps.list.push({ id: -1, name: '......' })
          setQids(tmps.list)
        } else {
          setQids(tmps.list)
        }
        setQIdFlag(false)
      }).catch()
    }
  }

  // 选中所属代理商开发
  const onSelectPrincipalId = (id) => {
    setFieldsValue({
      edit_transferInClientSaleId: undefined,
      edit_transferInClientServiceId: undefined,
      edit_accountId: undefined  // 主体清空
    })
    transferInClientSaleId=0
    transferInClientSaleName = ''
    transferInClientServiceId=0
    transferInClientServiceName=''
    var name = ''
    for (var i = 0; i < principalIds.length; i++) {
      if (principalIds[i].id == id) {
        name = principalIds[i].name
      }
    }
    transferInAgentPrincipalId = id
    transferInAgentPrincipalName = name

    let tmp = []
    tmp.push(transferInAgentPrincipalId)
    // tmp.push(transferInClientSaleId)
    // tmp.push(transferInClientServiceId)
    api.agentManufacturerGetTransferInAccount({
      agentId: transferInQId,
      clientId: clientIds,
      type: type,
      // staffIds: tmp.join(',')
      saleId: '',
      serviceId: '',
      agentStaffId: tmp.join(',')
    }, true).then(tmps => {
      setFundAccountList(tmps)
    })
  }

  // 筛选负责人
  // const principalIdSelect = (value, data) => {
  //   value = value ? value : (manufacturerData ? manufacturerData.transferInAgentPrincipalName : data.transferInAgentPrincipalName)
  //   var clientId = manufacturerData.clientId ? manufacturerData.clientId : data.clientId

  //   if (transferInQId) {
  //     api.agentManufacturerGetTransferInUser({
  //       clientId: clientId, name: value, type: 0, qId: transferInQId
  //     }).then(tmps => {
  //       for (var i = 0; i < tmps.length; i++) {
  //         tmps[i].name = tmps[i].staffName
  //       }
  //       setPrincipalIds(tmps)
  //       setKaifaFlag(false)
  //     }).catch()
  //   }
  // }

  // 选中销售
  const onSelectSaleId = (id) => {
    setFieldsValue({
      edit_transferInAgentPrincipalId: undefined
    })
    transferInAgentPrincipalId = 0
    transferInAgentPrincipalName = ''
    var name = ''
    for (var i = 0; i < saleIds.length; i++) {
      if (saleIds[i].id == id) {
        name = saleIds[i].name
      }
    }
    transferInClientSaleId = id
    transferInClientSaleName = name

    // let tmp = []
    // tmp.push(transferInAgentPrincipalId)
    // if(transferInClientSaleId){
    //   tmp.push(transferInClientSaleId)
    // }

    // if(transferInClientServiceId) {
    //   tmp.push(transferInClientServiceId)
    // }
    api.agentManufacturerGetTransferInAccount({
      agentId: transferInQId,
      clientId: clientIds,
      type: type,
      // staffIds: tmp.join(',')
      saleId: transferInClientSaleId ? transferInClientSaleId : '',
      serviceId: transferInClientServiceId ? transferInClientServiceId : '',
      agentStaffId: ''
    }, true).then(tmps => {
      setFundAccountList(tmps)
    })
  }

  // 筛选销售
  // const saleIdSelect = (value, data) => {
  //   // value = value ? value : (manufacturerData ? manufacturerData.transferInClientSaleName : data.transferInClientSaleName)
  //   // var clientId = manufacturerData.clientId ? manufacturerData.clientId : data.clientId

  //   // api.agentManufacturerGetTransferInUser({
  //   //   clientId: clientId, name: value, type: 1
  //   // }).then(tmps => {
  //   //   for (var i = 0; i < tmps.length; i++) {
  //   //     tmps[i].name = tmps[i].staffName
  //   //   }
  //   //   setSaleIds(tmps)
  //   //   setXiaoshouFlag(false)
  //   // }).catch()
  // }

  // 选中客服
  const onSelectServiceId = (id) => {
    setFieldsValue({
      edit_transferInAgentPrincipalId: undefined
    })
    transferInAgentPrincipalId=0
    transferInAgentPrincipalName = ''
    var name = ''
    for (var i = 0; i < serviceIds.length; i++) {
      if (serviceIds[i].id == id) {
        name = serviceIds[i].name
      }
    }
    transferInClientServiceId = id
    transferInClientServiceName = name

    // let tmp = []
    // tmp.push(transferInAgentPrincipalId)
    // if(transferInClientSaleId){
    //     tmp.push(transferInClientSaleId)
    // }

    // if(transferInClientServiceId) {
    //    tmp.push(transferInClientServiceId)
    // }

    api.agentManufacturerGetTransferInAccount({
      agentId: transferInQId,
      clientId: clientIds,
      type: type,
      // staffIds: tmp.join(',')
      saleId: transferInClientSaleId ? transferInClientSaleId : '',
      serviceId: transferInClientServiceId ? transferInClientServiceId : '',
      agentStaffId: ''
    }, true).then(tmps => {
      setFundAccountList(tmps)
    })
  }

  // 筛选客服
  // const serviceIdSelect = (value, data) => {
  //   // value = value ? value : (manufacturerData ? manufacturerData.transferInClientServiceName : data.transferInClientServiceName)
  //   // var clientId = manufacturerData.clientId ? manufacturerData.clientId : data.clientId

  //   // api.agentManufacturerGetTransferInUser({
  //   //   clientId: clientId, name: value, type: 2
  //   // }).then(tmps => {
  //   //   for (var i = 0; i < tmps.length; i++) {
  //   //     tmps[i].name = tmps[i].staffName
  //   //   }
  //   //   setServiceIds(tmps)
  //   //   setKefuFlag(false)
  //   // }).catch()
  // }

  //转入客户名称
  const onSelectClient = (value, Option) => {
    // console.log(value, Option)
    setClientIds(value)
    setTransferInClientName(Option.props.children)
    setFieldsValue({
      edit_transferInAgentPrincipalId: undefined,
      edit_transferInClientSaleId: undefined,
      edit_transferInClientServiceId: undefined
    })
    setFundAccountList([])
    setAccountName('')
    setAccountId('')
    transferInClientSaleId = 0
    transferInClientSaleName = ''
    transferInClientServiceId = 0
    transferInClientServiceName = ''
    transferInAgentPrincipalId = 0
    transferInAgentPrincipalName = ''
    if (type == 1) {
      api.agentManufacturerGetTransferInUser({
        clientId: value, type: 0, qId: qid
      }).then(tmps => {
        for (var i = 0; i < tmps.length; i++) {
          tmps[i].name = tmps[i].staffName
        }
        setPrincipalIds(tmps)
        setKaifaFlag(false)
      }).catch(err => console.log(err))
    } else {
      //客服
      api.agentManufacturerGetTransferInUser({
        clientId: value, type: 2
      }).then(tmps => {
        for (var i = 0; i < tmps.length; i++) {
          tmps[i].name = tmps[i].staffName
        }
        setServiceIds(tmps)
        setKefuFlag(false)
      }).catch()
      //销售
      api.agentManufacturerGetTransferInUser({
        clientId: value, type: 1
      }).then(tmps => {
        for (var i = 0; i < tmps.length; i++) {
          tmps[i].name = tmps[i].staffName
        }
        setSaleIds(tmps)
        setXiaoshouFlag(false)
      }).catch()
      //直销渠道开发
      api.agentManufacturerGetTransferInUser({
        clientId: value, type: 3
      }).then(tmps => {
        for (var i = 0; i < tmps.length; i++) {
          tmps[i].name = tmps[i].staffName
        }
        setPrincipalIds(tmps)
      }).catch()
    }
  }

  const onSearchClient = (value) => {
    // console.log(type)
    let val = value.trim()
    if (type == 1 && qid == '') {
      return message.warning('请先选择代理商名称')
    }
    if (val !== '') {
      api.getAgentFirmTransferGetCustom({ type: type, userType: 0, name: val, qId: qid }).then(res => {
        // console.log(res)
        if (res.list.length >= 50) {
          res.list.push({ id: -1, name: '......' })
          setTransferInClientList(res.list)
        } else {
          setTransferInClientList(res.list)
        }
      })
    }
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    api.agentManufacturerGetTransferFirmList({
      oaId: clientId,
      firmInfoId: search.ids,
      page: currentPage,
      limit: pageSize
    }, true).then(tmps => {
      setCount(tmps.count)
      setTransferFirmList(tmps.list)
    })
  }

  return (
    <>
      <div className="pro-detail">

        <Descriptions title={drawerTitle} layout="vertical" column={3}>
          <Descriptions.Item label="QID">{manufacturerData.qId}</Descriptions.Item>
          <Descriptions.Item label="代理商名称">{manufacturerData.agentName}</Descriptions.Item>
          <Descriptions.Item label="代理商客户名称">{manufacturerData.clientName}</Descriptions.Item>
        </Descriptions>
        <div className="line-box"></div>

        {processShow ?
          <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
          : null}

        {processShow ?
          <div className="promotionProcess">
            <Steps>
              {
                statusList.map((item, index) => {
                  let status = 'wait'
                  if (item.time && item.time.length > 1) {
                    status = 'process'
                  }
                  return <Step status={status}
                    key={index}
                    value={item.time}
                    title={item.nodeName}
                    description={
                      <>
                        {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                        <div title={item.statusName}>{item.statusName}</div>
                        <div title={item.time}>{item.time}</div>
                      </>
                    }
                  />
                })
              }
            </Steps>
          </div>
          : null}

        {processShow ?
          <div className="line-box"></div>
          : null}

        <Descriptions title="转出信息" className="pro-detail-smailtitle"></Descriptions>
        <Table
          dataSource={transferFirmList}
          rowKey="id"
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        // scroll={{x: 1400}}
        // onChange={false}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="厂商账号" dataIndex="account" />
          <Column title="厂商客户名称" dataIndex="vendorClient" />
          <Column title="关联主体" dataIndex="relBranch" />
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="供应商" dataIndex="supplierName" />
          <Column title="最后续费订单" dataIndex="lastOrderNo" />
          <Column title="创建时间" dataIndex="createTime" />
          <Column title="所属渠道代理商开发" dataIndex="relAgentStaffName" />
        </Table>
        <div className="line-box"></div>

        <Form onSubmit={formSubmits}>
          <Descriptions title={<><span style={{ color: 'red' }}>*</span><> 转入信息</>
          </>} layout="horizontal" column={3} style={{ paddingTop: '20px', marginBottom: 0 }}></Descriptions>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label="客户类型">
                {getFieldDecorator('edit_customType', {
                  initialValue: manufacturerData.type,
                  rules: [{ required: true, message: '请输入客户类型' }]
                })(
                  <Radio.Group>
                    <Radio value={0} onClick={() => onChooseRadio("0", manufacturerData)}>直销</Radio>
                    <Radio value={1}
                      onClick={() => onChooseRadio("1", manufacturerData)}>代理商</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            {dailishow && <Col span={9}>
              <Form.Item label="代理商名称">
                {getFieldDecorator('edit_qId', {
                  initialValue: manufacturerData.transferInQId,
                  rules: [{ required: true, message: '请输入代理商名称' }]
                })(
                  <Select placeholder="请输入代理商名称"
                    disabled={qIdFlag}
                    showSearch={true}
                    onSelect={onSelectQId}
                    onSearch={qIdSelect}
                    filterOption={false} style={{ width: 300 }}
                    autoFocus
                    dropdownMatchSelectWidth={false}
                  >
                    {/*<Option value={0}>请选择</Option>*/}
                    {qids.map(item => {
                      if (item.id === -1) {
                        return <Option style={{ fontSize: '22px', marginTop: '-14px', color: '#000000A6' }} key={item.id} disabled={true} value={item.id}>{item.name}</Option>
                      } else {
                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                      }
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>}
            <Col span={9}>
              <Form.Item label="（转入）客户名称">
                {getFieldDecorator('transferInClientId', {
                  // initialValue: search.id > 0 ? manufacturerData.transferInQId : undefined,
                  rules: [{ required: true, message: '（转入）客户名称' }]
                })(
                  <Select placeholder="（转入）客户名称"
                    showSearch={true}
                    onSelect={onSelectClient}
                    onSearch={onSearchClient}
                    filterOption={false}
                    style={{ width: 300 }}
                    autoFocus
                    dropdownMatchSelectWidth={false}
                  >
                    {transferInClientList.map(item => {
                      if (item.id === -1) {
                        return <Option style={{ fontSize: '22px', marginTop: '-14px', color: '#000000A6' }} key={item.id} disabled={true} value={item.id}>{item.name}</Option>
                      } else {
                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                      }
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            {/* <Col span={6}>
              {dailishow ?
                <Form.Item label="所属代理商开发">
                  {getFieldDecorator('edit_transferInAgentPrincipalId', {
                    initialValue: manufacturerData.transferInAgentPrincipalId,
                    rules: [{ required: true, message: '请输入代理商开发' }]
                  })(
                    <Select 
                      placeholder="请输入所属代理商开发"
                      disabled={kaifaFlag}
                      showSearch={true}
                      onSelect={onSelectPrincipalId}
                      optionFilterProp="children"
                      style={{ width: 200 }}
                      autoFocus
                    >
                      {principalIds.map(item => <Option key={item.id}
                        value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
                :
                <Form.Item label="所属销售">
                  {getFieldDecorator('edit_transferInClientSaleId', {
                    initialValue: manufacturerData.transferInClientSaleId,
                    rules: [{ required: false, message: '请输入所属销售' }]
                  })(
                    <Select 
                      placeholder="请输入所属销售"
                      disabled={xiaoshouFlag}
                      showSearch={true}
                      onSelect={onSelectSaleId}
                      optionFilterProp="children"
                      style={{ width: 200 }}
                      autoFocus
                    >
                      {saleIds.map(item => <Option key={item.id}
                        value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              }
            </Col> */}
            {
              dailishow ? null :
                <Col span={6}>
                  <Form.Item label="所属销售">
                    {getFieldDecorator('edit_transferInClientSaleId', {
                      initialValue: manufacturerData.transferInClientSaleId === 0? undefined : manufacturerData.transferInClientSaleId,
                      rules: [{ required: false, message: '请输入所属销售' }]
                    })(
                      <Select
                        placeholder="请输入所属销售"
                        disabled={xiaoshouFlag}
                        showSearch={true}
                        onSelect={onSelectSaleId}
                        // onSearch={saleIdSelect}
                        optionFilterProp="children"
                        // filterOption={false}
                        style={{ width: 200 }}
                        autoFocus
                      >
                        {/*<Option value={0}>请选择</Option>*/}
                        {saleIds.map(item => <Option key={item.id}
                          value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
            }
            {
              dailishow ? null :
                <Col span={6}>
                  <Form.Item label="所属客服">
                    {getFieldDecorator('edit_transferInClientServiceId', {
                      initialValue: manufacturerData.transferInClientServiceId === 0? undefined: manufacturerData.transferInClientServiceId,
                      rules: [{ required: false, message: '请输入所属客服' }]
                    })(
                      <Select
                        placeholder="请输入所属客服"
                        disabled={kefuFlag}
                        showSearch={true}
                        onSelect={onSelectServiceId}
                        // onSearch={serviceIdSelect}
                        optionFilterProp="children"
                        // filterOption={false}
                        style={{ width: 200 }}
                        autoFocus
                      >
                        {/*<Option value={0}>请选择</Option>*/}
                        {serviceIds.map(item => <Option key={item.id}
                          value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
            }
            <Col span={6}>
              <Form.Item label="所属代理商开发">
                {getFieldDecorator('edit_transferInAgentPrincipalId', {
                  initialValue: manufacturerData.transferInAgentPrincipalId,
                  rules: [{ required: dailishow? true:false, message: '请输入代理商开发' }]
                })(
                  <Select
                    placeholder="请输入所属代理商开发"
                    disabled={dailishow ? kaifaFlag : false}
                    showSearch={true}
                    onSelect={onSelectPrincipalId}
                    // onSearch={principalIdSelect}
                    optionFilterProp="children"
                    // filterOption={false} 
                    style={{ width: 200 }}
                    autoFocus
                  >
                    {/*<Option value={0}>请选择</Option>*/}
                    {principalIds.map(item => <Option key={item.id}
                      value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {dailishow ? null :
            <div style={{ marginBottom: 5, color: 'red' }}>
              直销客户的厂商ID关联的销售和客服，不能与代理商的客户的厂商ID关联的渠道所属开发同时存在
            </div>
          }
          <div className="line-box"></div>
          <Descriptions title={<><span style={{ color: 'red' }}>*</span> <>转入主体</>
          </>} className="pro-detail-smailtitle mainNameBodyRadio">
            <Form.Item label="" name="layout">
              {getFieldDecorator('edit_accountId', {
                initialValue: search.id > 0 ? manufacturerData.account : undefined,
                rules: [{ required: true, message: '请选择主体' }]
              })(
                <Radio.Group onChange={(e) => selectZhuTi(e.target.value)}>
                  {fundAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                </Radio.Group>
              )}
            </Form.Item>
          </Descriptions>
          <div className="line-box"></div>
          <Descriptions title="其他信息" className="pro-detail-smailtitle" layout="vertical" column={1}>
            <Descriptions.Item label="附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：.rar、zip、doc、docx、pdf、jpg, .png, .jpeg, .bmp 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </Descriptions.Item>
            <dItem label="备注">
              {getFieldDecorator('edit_remark', {
                initialValue: manufacturerData.remark, rules: [{ required: false, message: '请输入备注' }]
              })(<Input.TextArea placeholder="请输入备注" rows={4} cols={150} />)}
            </dItem>
          </Descriptions>
          <div className="line-box"></div>

          {processShow ?
            <Descriptions
              title="审批记录"
              layout="horizontal"
              className="pro-detail-smailtitle"
            >
            </Descriptions>
            : null}

          {processShow == true &&
            <>
              <Table
                dataSource={logConList}
                rowKey="id"
                pagination={false}
              >
                <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
                <Column title="审批时间" dataIndex="addTime" />
                <Column title="状态" dataIndex="status" />
                <Column title="备注" dataIndex="chargeMsg" />
              </Table>
              {manufacturerData.auditStatusName &&
                <Descriptions title="" className="pro-detail-smailtitle">
                  <dItem label="当前状态">
                    <Tag color="#2db7f5">{manufacturerData.auditStatusName}</Tag>
                  </dItem>
                </Descriptions>
              }
            </>
          }
          <BtnGroup onCancel={() => history.goBack()} loading={saveLoading} />
        </Form>
      </div>
    </>
  )
}

export default Form.create()(ManufacturerAdd)