/**
 * 模块名称: 代理商信息变更申请
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Input,
  Icon,
  Form,
  Select,
  message,
  Upload,
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const Option = Select.Option
const { Dragger } = Upload

const AgentNameAdd = (props) => {
  const { history } = props
  const { getFieldDecorator, validateFields } = props.form
  const search = parseSearch(props.location.search)
  const [detailData, setDetailData] = useState({})
  // const [detailDataId, setdetailDataId] = useState('')
  const [verifyTypeList, setVerifyTypeList] = useState([])
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)
  // const [visibleEdit, setVisibleEdit] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    // setVisibleEdit(true)

    var qId = search.qId ? search.qId : '0'

    //0直销,1代理商
    api.getCusTomInfo({ relationType: 1, relationId: qId }).then(data => {
      setDetailData(data)
      // setdetailDataId(data.id)
    })

    api.getCommon({ type: 'verify_type' }, true).then(tmps => {
      setVerifyTypeList(tmps)
    })
  }, [])

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png, .jpeg, .bmp',
		allowSizeType: 2
	})

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {

      if (!err) {
        setSaveLoading(true)
        api.agentChangeHandleName({
          qId: detailData.relationId,
          newName: vals.edit_newName,
          newVerifyType: vals.edit_newVerifyType,
          newVerifyNumber: vals.edit_newVerifyNumber,
          affix: fileList,
          remark: vals.edit_remark,
        })
          .then(() => {
            setSaveLoading(false)
            // setVisibleEdit(false)
            message.success('操作成功')
            goList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const goList = () => {
    history.push({
      pathname: '/agent/nameManage'
    })
  }

  return (
    <>
      <div className="pro-detail">
        <Descriptions title="信息变更申请" layout="horizontal " className="pro-detail-title"></Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal" column={2}>
          <Descriptions.Item label="QID">{detailData.relationId}</Descriptions.Item>
          <Descriptions.Item label="代理商名称">{detailData.relationName}</Descriptions.Item>
          <Descriptions.Item label="认证号码类型">{detailData.verifyTypeCn}</Descriptions.Item>
          <Descriptions.Item label="认证号码">{detailData.verifyNumber}</Descriptions.Item>
        </Descriptions>
        <div className="line-box"></div>
        <Form onSubmit={formSubmits} {...formItemLayout}>
     
          <Descriptions title="变更后信息" className="pro-detail-smailtitle" layout="vertical" column={3}>
            <Descriptions.Item label={<><span style={{ color: 'red' }}>*</span>代理商名称</>}>
              <Form.Item label="">
                {getFieldDecorator('edit_newName', {
                  initialValue: detailData.newName,
                  rules: [{ required: true, message: '请输入代理商名称' }]
                })(<Input style={{ width: 200 }} placeholder="请输入" />)}
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={<><span style={{ color: 'red' }}>*</span>认证号码类型</>}>
              <Form.Item label="">
                {getFieldDecorator('edit_newVerifyType', {
                  initialValue: detailData.newVerifyType,
                  rules: [{ required: true, message: '请选择认证号码类型' }]
                })(
                  <Select placeholder="请选择" style={{ width: 177 }}>
                    {verifyTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={<><span style={{ color: 'red' }}>*</span>认证号码</>}>
              <Form.Item label="">
                {getFieldDecorator('edit_newVerifyNumber', {
                  initialValue: detailData.newVerifyNumber,
                  rules: [{ required: true, message: '请输入认证号码' }]
                })(<Input style={{ width: 200 }} placeholder="请输入" />)}
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <div className="line-box"></div>

          {/* {visibleEdit ?  */}
          <Descriptions title="" layout="vertical">
            <Descriptions.Item label="附件">
              <div className="dragger-box" ref={draggerRef}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：.rar、zip、doc、docx、pdf、jpg, .png, .jpeg, .bmp 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </Descriptions.Item>
          </Descriptions>
          <div className="line-box"></div>

          <Descriptions title="" layout="vertical">
            <Descriptions.Item label="备注">
              {getFieldDecorator('edit_remark', { initialValue: detailData.remark })(<Input.TextArea placeholder="请输入" rows={4} style={{ width: '560px' }} />)}
            </Descriptions.Item>
          </Descriptions>
          <BtnGroup onCancel={() => goList()} loading={saveLoading} />
        </Form>
        {/* : <div></div> */}
        {/* } */}

      </div>
    </>
  )
}

export default Form.create()(AgentNameAdd)