/**
 * 模块名称: 代理商转移 -> 转移申请
 * @author yue
 */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '@/api'
import { Button, Col, Descriptions, Form, Icon, Input, message, Row, Select, Spin, Upload } from 'antd'
import { parseSearch } from "../../../utils";
import { upLoadModule } from "../../../utils/common";
// import BtnGroup from "../../../components/BtnGroup";
import PublicAgentTransfer from "./PublicAgentTransfer";
import { auditRecordRender } from "./AgentTransferDetail";

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
let disabled = true

const AddAgentTransfer = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form

  const { location, history } = props

  const [fileList, setFileList] = useState([])

  const userInfo = useSelector(state => state.userInfo)

  const draggerRef = useRef(null)

  //get参数
  const search = parseSearch(location.search)

  //驳回重提id
  const editId = search !== undefined ? search.id : '';

  //驳回重提QID
  const [editQID, setEditQID] = useState([])

  //转出负责人名称
  const [userName, setUserName] = useState(undefined);

  //提交等待
  const [spinLoading, setSpinLoading] = useState(true);

  const [listParams, setListParams] = useState(undefined)

  const [auditRecord, setAuditRecord] = useState([])
  const [auditStatusName, setAuditStatusName] = useState(undefined)
  const [nameVal, setNameVal] = useState('')
  const [btnFlag, setBtnFlag] = useState(false)

  useEffect(() => {
    disabled = true
    initData()
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  // 输入转入员工编号
  const onNameChange = (e) => {
    setTransferInId(undefined)
    setTransferInName(undefined)
    setTransferInLeader(undefined)
    setTransferInDepartment(undefined)
    setNameVal(e.target.value)
  }

  const onNameBlue = () => {
    if (userInfo.staffNo == nameVal) {
      return message.warning('转出人与转入人不能相同')
    }
    setBtnFlag(false)
    geTransferInUserList(nameVal)
  }

  const onNameClick = () => {
    if (userInfo.staffNo == nameVal) {
      return message.warning('转出人与转入人不能相同')
    }
    geTransferInUserList(nameVal)
  }
  /**
   * 获取详情数据
   * @param editId
   * @returns {Q.Promise<any> | Promise<T | never> | * | undefined}
   */
  function getDetail(editId) {
    return api.getAgentTransferDetail({ id: editId, type: 0 })
      .then(res => {
        geTransferInUserList(res.transferInNo)
        // geTransferInUserList(res.transferInName).then(function () { 
        //     setFieldsValue({
        //         transferInId: res.transferInId ? res.transferInId : undefined,
        //     })

        //     setTransferInLeader(res.transferInLeadName);
        //     setTransferInDepartment(res.transferInDepartmentName);
        // });
        settransferInNo(res.transferInNo)
        setFieldsValue({
          remark: res.remark ? res.remark : undefined
        })

        setEditQID(res.qId);
        setUserName(res.transferOutName);

        //初始化转入人信息
        // setTransferInId(JSON.stringify({
        //     "id": res.transferInId,
        //     "staffName": res.transferInName,
        //     "leaderName": res.transferInLeadName,
        //     "departmentName": res.transferInDepartmentName
        // }));
        // setTransferInLeader(res.transferInLeadName);
        // setTransferInDepartment(res.transferInDepartmentName);

        //公共组件参数
        setListParams({ qId: res.qId.join(','), transferOutId: res.transferOutId })

        setAuditRecord(res.audit_log)
        setAuditStatusName(res.auditStatusName)

        if (res.affix.length) {
          res.affix.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(res.affix.map((item, index) => ({ ...item, uid: index })))
        }
        setSpinLoading(false);
      })
      .catch(() => {
        onCancel();
      })
  }

  //初始化
  const initData = () => {

    //编辑详情
    if (editId) {
      getDetail(editId);
    } else {
      //待转移代理商QID
      let qId = search.qId

      //没有数据跳到列表
      if (!qId) {
        onCancel();
      }

      setListParams({ qId, transferOutId: '' })
      setSpinLoading(false);
    }
  }

  // 获取转出人信息
  const [transferInNo, settransferInNo] = useState('')
  //转出人id
  const [transferInId, setTransferInId] = useState(undefined)
  //转出人name
  const [transferInName, setTransferInName] = useState(undefined)
  //转出人直属上级
  const [transferInLeader, setTransferInLeader] = useState(undefined)
  //转出人部门
  const [transferInDepartment, setTransferInDepartment] = useState(undefined)
  // const [transferInUserList, setTransferInUserList] = useState([])

  const geTransferInUserList = (val) => {
    // setFieldsValue({transferInId: ''})
    // setTransferInLeader('')
    // setTransferInDepartment('')
    if (val) {
      return api.getTransferInUserList({ no: val }).then(res => {
        // setTransferInUserList(res[0])
        if (res.length !== 0) {
          setTransferInId(res[0].id)
          setTransferInName(res[0].staffName)
          setTransferInLeader(res[0].leaderName)
          setTransferInDepartment(res[0].departmentName)
        } else {
          message.error('员工编号有误')
          return
        }
      })
    } else {
      // return (new Promise(function () {
      //     setTransferInUserList([])
      // }))
    }
  }

  /**
   * 选择转出人操作
   * @param option
   */
  const handleChangeTransferInUserList = (option) => {
    let jsonData = JSON.parse(option.props['data-value']);
    setTransferInId(option.props['data-value'])
    setTransferInLeader(jsonData.leaderName)
    setTransferInDepartment(jsonData.departmentName)
  }

  //跳转到列表页
  const onCancel = () => {
    history.push({
      pathname: '/agent/agentTransfer'
    })
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    setSpinLoading(true);
    e.preventDefault()
    if (disabled) {
      disabled = false
      let timer = setTimeout(() => {
        disabled = true
        clearTimeout(timer)
      }, 1000)
    } else {
      setSpinLoading(false);
      message.error('请不要频繁点击')
      return
    }
    validateFields((err, vals) => {
      if (!err) {
        let params = Object.assign({}, vals)
        let uploadList = []
        for (let i = 0, k = fileList.length; i < k; i++) {
          if (fileList[i].status !== 'removed') {
            uploadList.push({
              url: fileList[i].url,
              name: fileList[i].name
            })
          }
        }

        //附件
        params.affix = uploadList

        //判断是否为驳回重提
        if (editId) {
          params.id = editId
          params.qId = editQID;
        } else {
          //待转移代理商QID
          let qId = search.qId;
          params.qId = qId.split(',');
        }
        if (transferInId) {
          //转出信息
          // let transferUserInfo = JSON.parse(transferInId);
          params.transferInId = transferInId;
          params.transferInName = transferInName;
          params.transferInLeadName = transferInLeader;
          params.transferInDepartmentName = transferInDepartment;
        } else {
          setSpinLoading(false);
          return message.error('缺少表单信息！');
        }

        api.handleAgentTransfer(params)
          .then(res => {
            message.success('操作成功')
            //跳转列表
            onCancel();
          })
          .catch(() => {
            setSpinLoading(false);
          });
      } else {
        setSpinLoading(false);
      }
    })
  }

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .bmp, .png, .pptx, .PDF, .csv, .xlsx',
    allowSizeType: 4
  })

  return (
    <Spin spinning={spinLoading}>
      <div className="add-newask-detail">
        <Descriptions title="基础详情页" layout="horizontal" className="pro-detail-title"
          style={{ borderBottom: 'none' }}>
          <dItem label="负责人（转出）">{userName ? userName : userInfo.staffName}</dItem>
        </Descriptions>

        <div className="blank-line"></div>
        <div className="step-wrap">
          <PublicAgentTransfer
            listParams={listParams}
          />
        </div>
        <Form onSubmit={formSubmit} {...formItemLayout}>
          <div style={{ fontWeight: 'bold', marginBottom: 8 }}><span style={{ fontSize: '15px' }}> 转入信息（<font style={{ color: 'red' }}>注意：代理商转出完成后，所有代理商相关数据将转移至转入人</font>）</span></div>
          {/* <FormItem label="转入人">
                        <Row gutter={24}>
                            <Col span={8}>
                                {getFieldDecorator('transferInId', {
                                    initialValue: transferInId,
                                    rules: [{required: true, message: '请输入转入人'}]
                                })(
                                    <Select
                                        showSearch
                                        showArrow
                                        placeholder="请输入转入人"
                                        onSearch={geTransferInUserList}
                                        // onChange={handleChangeTransferInUserList}
                                        onChange={(value, option) => handleChangeTransferInUserList(option)}
                                        notFoundContent='未搜索到相关转入人'
                                        filterOption={false}
                                    >
                                        {transferInUserList.map(item => <Option key={item.id}
                                                                                value={item.id}
                                                                                data-value={JSON.stringify(item)}>{item.staffName}</Option>)}
                                    </Select>
                                )}
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={5}>
                                {transferInLeader && `直属上级：${transferInLeader}`}
                            </Col>
                            <Col span={10}>
                                {transferInDepartment && `所属部门：${transferInDepartment}`}
                            </Col>
                        </Row>
                    </FormItem> */}
          <FormItem label="转入人员工编号">
            <Row gutter={24}>
              <Col span={4}>
                {getFieldDecorator('transferInNo', {
                  initialValue: transferInNo,
                  rules: [{ required: true, message: '请输入转入人员工编号' }]
                })(
                  <Input onChange={onNameChange} onBlur={onNameBlue} onFocus={() => setBtnFlag(true)}></Input>
                )}
              </Col>
              <Col span={2}>
                {btnFlag ? <>
                  <Button type="primary" disabled={btnFlag} onClick={onNameClick} style={{display: 'none'}}>确认</Button>
                  <Button type="primary">确认</Button>
                </> : <Button type="primary" onClick={onNameClick}>确认</Button>}
              </Col>
              <Col span={1}>
              </Col>
              <Col span={6}>
                {transferInName && `转入人姓名:${transferInName}`}
              </Col>
              <Col span={5}>
                {transferInLeader && `直属上级：${transferInLeader}`}
              </Col>
              <Col span={6}>
                {transferInDepartment && `所属部门：${transferInDepartment}`}
              </Col>
            </Row>
            {
              transferInName && <Row gutter={24}>
                <Col span={7}>
                </Col>
                <Col span={6}>
                  {`转出人姓名:  ${userInfo.staffName}`}
                </Col>
                <Col span={5}>
                  {`直属上级：${userInfo.leaderName}`}
                </Col>
                <Col span={6}>
                  {`所属部门：${userInfo.departmentName}`}
                </Col>
              </Row>
            }
          </FormItem>
          <Row gutter={8}>
            <FormItem label="上传附件">
              <Col span={12}>
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      支持扩展名：.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .bmp, .png, .pptx, .PDF, .csv, .xlsx
                                        </p>
                  </Dragger>
                </div>
              </Col>
            </FormItem>

          </Row>
          <Row gutter={8}>
            <FormItem label="备注">
              <Col span={12}>
                {getFieldDecorator('remark')(
                  <Input.TextArea className="content-area" placeholder="请输入备注" rows={4}
                    style={{ width: "100%" }} />
                )}
              </Col>
            </FormItem>
          </Row>
          {
            editId && auditRecordRender(auditRecord, auditStatusName)
          }

          {/* <BtnGroup onCancel={onCancel} loading={false}/>*/}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" disabled={transferInId ? false : true} style={{ marginLeft: 10 }} htmlType="submit">确定</Button>
          </div>
        </Form>
      </div>
    </Spin>
  )
}
export default Form.create()(AddAgentTransfer)