/**
 * 模块名称: 认款管理
 * @author wangchanghong@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
// import Details from './Details'
import Add from './Details'
import './assets/style.scss'

const Agent = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        {/* <Route path={match.path + '/detail'} component={Details} /> */}
        <Route path={match.path + '/agentNewDetail'} component={Add} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Agent