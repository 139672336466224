/**
 * 模块名称: 代理商详情页
 * @author wangchanghong@372163.com
 */

import React, {useState, useEffect, useRef} from 'react'
import {
    Descriptions,
    Steps,
    Button,
    Modal,
    Input,
    Icon,
    Form,
    Upload,
    message,
    Spin, Table, Select, Col, Row, Drawer
} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import { upLoadModule } from '@/utils/common'
import CryptoJS from 'crypto-js'


const {Dragger} = Upload
const DItem = Descriptions.Item
const {Step} = Steps
const {TextArea} = Input
const { Column } = Table

let id = 0;

const Detail = (props) => {
    const {getFieldDecorator, setFieldsValue, validateFieldsAndScroll} = props.form
    const {location, history} = props
    const [detailDataId, setDetailDataId] = useState('')
    const [logConList, setLogConList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [buttonAuthList, setButtonAuthList] = useState([])
    const [passModalVisible, setModalVisible] = useState(false)
    const [turnModalVisible, setTurnModalVisible] = useState(false)
    const [textAreaVal, setTextAreaVal] = useState('')
    const [textDisplay, setTextDisplay] = useState(true)
    const [pasDesc, setPasDesc] = useState('')
    const [fileList, setFileList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false)
    const [canEdit, setCanEdit] = useState(true)
    const [detailLoading, setDetailLoading] = useState(true)
    const [reload, setReload] = useState({})
    const [dailishang, setDailishang] = useState({})
    const [contractsList, setContractsList] = useState([])
    const [verifyTypeList, setVerifyTypeList] = useState([])
    const [hetongVsible, setHetongVsible] =  useState(false)
    const [hetongList, setHetongList] = useState([])
    const [fetching,setFetching] = useState(false)
    const [contracts,setContracts] = useState([])
    const [titleName, setTitleName] = useState('代理商详情')

    const search = parseSearch(location.search)
    const draggerRef = useRef(null)

    id = search.id
    useEffect(() => {
        // console.log(location.pathname.indexOf('/agent/manage/agentNewAdd'))
        if(location.pathname.indexOf('/agent/manage/agentNewAdd') > -1){
            setTitleName('新增代理商')
            const apis = []
            setDetailLoading(true)

            apis.push(api.getClientVerifyTypes())

            Promise.all(apis).then((res) => {
                setVerifyTypeList(res.shift())
                if(id){
                    getDetail(id)
                }else{
                    setCanEdit(true)
                    setDetailLoading(false)
                }
            })
        }else{
            setTitleName('代理商详情')
            let plaintext = search.sign
            let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
        
            if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
                const apis = []
                setDetailLoading(true)
        
                apis.push(api.getClientVerifyTypes())
        
                Promise.all(apis).then((res) => {
                    setVerifyTypeList(res.shift())
                    if(id){
                        getDetail(id)
                    }else{
                        setCanEdit(true)
                        setDetailLoading(false)
                    }
                })
            } else {
              message.error('没有本页访问权限')
              setTimeout(() => {
                history.go(-2)
              }, 1000)
            }     
        }
    }, [])

    const getDetail = (id) => {
        setDetailLoading(true)
        api.agentVerifyGetDetail({id:id, type:0}).then(
            detail => {
                if (search.msgId) {
                    api.setMessageReaded({ ids: [search.msgId] })
                }
                setDetailDataId(id)
                setStatusList(detail.audit_flow)
                setButtonAuthList(detail.auditAuth || {})
                setLogConList(detail.audit_log)

                const dailishang = {
                    customerName : detail.name,
                    contractNo: detail.contract,
                }

                const contracts = detail.contract.split(',')
                const contractsList = [];
                contracts.map(item => {
                    contractsList.push({
                        customerName : detail.name,
                        contractNo: item,
                    })
                })
                detail.affix.forEach((item, index) => {
                    fileList.push({
                        status: 'done',
                        uid: index,
                        name: item.name,
                        url: item.url
                    })
                })
                setFileList(detail.affix.map((item, index) => ({...item, uid: index})))

                setContractsList(contractsList)

                setDailishang(dailishang)
                setContracts(detail.contract.split(','))

                setFieldsValue({
                    contactAddress: detail.contactAddress,
                    contactFax: detail.contactFax,
                    contactMail: detail.contactMail,
                    contactMobile: detail.contactMobile,
                    contactName: detail.contactName,
                    contactTel: detail.contactTel,
                    contract: contractsList.map(item => JSON.stringify(item)),
                    remark: detail.remark,
                    verifyNumber:detail.verifyNumber,
                    verifyType:detail.verifyType,
                })

                setCanEdit(detail.userAuth !== null && detail.userAuth.allowRakeUp === true)
                setDetailLoading(false)
            }
        )
    }

    // 通过
    const onBtnPassShow = () => {
        setModalVisible(true)
    }

    const onPassCancel = () => {
        setModalVisible(false)
    }

    const onPassDescChange = (e) => {
        setPasDesc(e.target.value)
    }

    //通过
    const onApprove = () => {
        setDetailLoading(true)
        api.agentVerifyAudit({
            id: detailDataId,
            type: 0,
            remark:pasDesc.trim()
        }).then(data => {
            setModalVisible(false)
            getDetail(detailDataId) //确认通过后
        }).finally(() => {
            setModalVisible(false)
        })
    }

    // 驳回
    const onBtnTurnShow = () => {
        setTurnModalVisible(true)
        setTextDisplay(true)
    }

    const onTurnCancel = () => {
        setTurnModalVisible(false)
    }

    //驳回
    const onTurnApprove = () => {
        if (buttonAuthList.reject_msg_require) {
            if (textAreaVal.trim() === '') {
                setTextDisplay(false)
                return
            }
        }
        setDetailLoading(true)
        api.agentVerifyAudit({
            id: detailDataId,
            type: 1,
            remark:textAreaVal.trim()
        }).then(data => {
            setTurnModalVisible(false)
            getDetail(detailDataId) //确认驳回后
        }).finally(() => {
            setTurnModalVisible(false)
        })
    }

    const onDescChange = (e) => {
        setTextAreaVal(e.target.value)
        setTextDisplay(true)
    }

    // 驳回样式
    const turnNode = () => {
        return <>
            确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C"/>
        </>
    }

    // 同意样式
    const agreeNode = () => {
        return <>
            确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
        </>
    }

    // 转上级
    const onTurnUp = () => {
        setDetailLoading(true)
        api.agentVerifyAudit({
            id: detailDataId,
            type: 2
        }).then(data => {
            getDetail(detailDataId)
        }).finally(() => {
            setDetailLoading(false)
        })
    }

    // 补充资料
    const onReplenish = () => {
        setDetailLoading(true)
        api.fundMoneyClaimAudit({
            id: detailDataId,
            type: 3
        }).then(data => {
            getDetail(detailDataId)
        }).finally(() => {
            setDetailLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        validateFieldsAndScroll((err, vals) => {
            if (!err) {
                setSubmitLoading(true)
                let submitVals = {...vals}
                if(detailDataId){
                    submitVals.id = detailDataId
                }
                submitVals.name = dailishang.customerName
                submitVals.affix = []
                submitVals.contract = contracts

                fileList.map(item => {
                    submitVals.affix.push(item)
                })

                api.agentVerifyHandle(submitVals).then(
                    res => {
                        history.push('/agent/agentNew')
                        getDetail(res)
                        setReload({})
                    }
                ).finally(() => setSubmitLoading(false))

            } else {
                for (let i in err) {
                    message.error(err[i].errors[0].message)
                }
            }


        })
    }

    // 文件上传
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png',
        allowSizeType: 2,
        limitNum: 20
	})

    const onSearchContract = (value) => {
        if(!value){
            setContractsList([])
            setDailishang({})
            return;
        }
        setFetching(true)
        api.agentVerifyGetUserContractNo({likeContractNo:value})
            .then(res => {
                setFetching(false)
                setContractsList(res.map(item => {
                    return {...item, disabled: dailishang.customerName !== undefined ? dailishang.customerName !== item.customerName : false}
                }))
            })
    }

    const onChangeContract = (value) => {
        if(value.length === 0){
            setContractsList(contractsList.map(item => {
                return {...item, disabled: false}
            }))
        }else if(value.length === 1){
            const dailishang = JSON.parse(value[0])

            setContractsList(contractsList.map(item => {
                return {...item, disabled: dailishang.customerName !== item.customerName}
            }))

            setDailishang(dailishang)
        }

        const contracts = [];
        value.map(item => {
            const dailishang = JSON.parse(item)
            contracts.push(dailishang.contractNo)
        })


        setContracts(contracts)
        // setFieldsValue({contract: value})
    }


    const showHetong = (contract) => {
        api.agentGetContractList({contract})
            .then((res) => {
                setHetongVsible(true)
                setHetongList(res.list)
            })
    }

    return (
        <Spin spinning={detailLoading}>
            <Form onSubmit={handleSubmit}>
                <div className="pro-detail">
                    <Descriptions title={titleName} layout="horizontal">
                        <DItem label="代理商名称">{dailishang.customerName}</DItem>

                        <DItem label="合同期限">
                            {contracts.length > 0 &&
                            <a className="operate-icon" onClick={() => showHetong(contracts.join(','))}>{"查看"}</a>
                            }

                        </DItem>
                    </Descriptions>

                    {!canEdit && <>
                        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
                        <div className="promotionProcess">
                            <Steps>
                                {
                                    statusList.map((item, index) => {
                                        let status = 'wait'
                                        if (item.time && item.time.length > 1) {
                                            status = 'process'
                                        }
                                        return <Step status={status}
                                                     key={index}
                                                     value={item.time}
                                                     title={item.nodeName}
                                                     description={
                                                         <>
                                                             {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                                                             <div title={item.statusName}>{item.statusName}</div>
                                                             <div title={item.time}>{item.time}</div>
                                                         </>
                                                     }
                                            // {`${item.persons.map((item) => item)} ${item.statusName} ${item.time}`}
                                        />
                                    })
                                }
                            </Steps>
                        </div>
                    </>}

                    <div className="line-box"></div>

                    <Descriptions title="代理商信息" className="pro-detail-smailtitle"></Descriptions>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="合同号">
                                {getFieldDecorator('contract', {
                                    rules: [{required: true, message: '请选择合同号'}]
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="请选择"
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={onSearchContract}
                                        onChange={onChangeContract}
                                        disabled={!canEdit}
                                    >
                                        {contractsList.map(item => (
                                            <Select.Option key={ JSON.stringify({customerName:item.customerName, contractNo:item.contractNo})} disabled={item.disabled !== undefined ? item.disabled : false }>{item.contractNo}（{item.customerName}）</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="认证号码类型">
                                {getFieldDecorator('verifyType', {
                                    rules: [{required: true, message: '请选择认证号码类型'}]
                                })(
                                    <Select placeholder="请选择" disabled={!canEdit}>
                                        {verifyTypeList.map(item => <Select.Option key={item.id}
                                                                                   value={item.id}>{item.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="认证号码">
                                {getFieldDecorator('verifyNumber', {
                                    rules: [{required: true, message: '请填写认证号码'}]
                                })(
                                    <Input disabled={!canEdit} placeholder="请输入认证号码"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="line-box"></div>
                    <Descriptions title="联系人信息" className="pro-detail-smailtitle"></Descriptions>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="联系人姓名">
                                {getFieldDecorator('contactName', {
                                    rules: [{required: true, message: '请输入客户姓名'}]
                                })(
                                    <Input disabled={!canEdit} placeholder="请输入客户姓名"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="手机号码">
                                {getFieldDecorator('contactMobile', {
                                    rules: [{required: true, message: '请输入手机号码'}]
                                })(
                                    <Input disabled={!canEdit} placeholder="请输入手机号码"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="邮箱">
                                {getFieldDecorator('contactMail')(
                                    <Input disabled={!canEdit} placeholder="请输入邮箱"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="座机">
                                {getFieldDecorator('contactTel')(
                                    <Input disabled={!canEdit} placeholder="请输入座机"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="传真">
                                {getFieldDecorator('contactFax')(
                                    <Input disabled={!canEdit} placeholder="请输入传真"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="地址">
                                {getFieldDecorator('contactAddress')(
                                    <Input disabled={!canEdit} placeholder="请输入地址"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>


                    <div className="line-box"></div>
                    <Descriptions title="附件上传" className="pro-detail-smailtitle"></Descriptions>
                    <Form.Item label="">
                        <div className="dragger-box" ref={draggerRef}>
                            {canEdit ?
                                <Dragger
                                    {...uploadFiles}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox"/>
                                    </p>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">
                                        附件支持格式：rar、zip、docx、xlsx、bmp、jpg、png 支持添加多个附件，单个附件大小不超过5M，文件数量不超过20个。
                                    </p>
                                </Dragger> :
                                <div className="content">
                                    {
                                        fileList.length ? fileList.map((item, index) => {
                                            return <div key={index}><Icon style={{color: '#aaa'}} type='paper-clip'/><a
                                                href={item.url} target="_blank"
                                                rel="noopener noreferrer">{item.name}</a></div>
                                        }) : '无'
                                    }
                                </div>
                            }
                        </div>
                    </Form.Item>
                    <div className="line-box"></div>
                    <Descriptions title="备注" className="pro-detail-smailtitle"></Descriptions>
                    <Form.Item label="">
                        {getFieldDecorator('remark')(
                            <TextArea disabled={!canEdit} maxLength={100} rows={3}></TextArea>
                        )}
                    </Form.Item>


                    {
                        logConList.length > 0 &&
                        <>
                            <div className="line-box"></div>
                            <Table
                                dataSource={logConList}
                                rowKey="id"
                                pagination={false}
                                style={{marginTop: '10px'}}
                            >
                                <Table.Column title="申请人/审批人" dataIndex="userInfo.staffName"/>
                                <Table.Column title="审批时间" dataIndex="addTime"/>
                                <Table.Column title="状态" dataIndex="status"/>
                                <Table.Column title="备注" dataIndex="chargeMsg"/>
                            </Table>
                        </>
                    }

                    <div className="btn-setting-agent">
                        <Button hidden={!canEdit} type="primary" htmlType="submit" loading={submitLoading}>提交</Button>
                        {/*<Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>*/}
                        <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
                        <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
                        <Modal
                            // title="确认驳回"
                            title={turnNode()}
                            visible={turnModalVisible}
                            onOk={onTurnApprove}
                            onCancel={onTurnCancel}
                        >
                            <div>
                                <span>审批意见:</span>
                                <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange}
                                                maxLength={100}/>
                            </div>
                            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
                        </Modal>
                        <Button hidden={!buttonAuthList.hasChargerPower} type="primary"
                                onClick={onBtnPassShow}>通过</Button>
                        <Modal
                            // title="确认通过"
                            title={agreeNode()}
                            visible={passModalVisible}
                            onOk={onApprove}
                            onCancel={onPassCancel}
                        >
                            <div>
                                <span>审批意见:</span>
                                <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange}
                                                maxLength={100}/>
                            </div>
                        </Modal>
                    </div>
                </div>

            </Form>

            <Drawer
                title="合同明细记录表"
                width={1000}
                onClose={() => setHetongVsible(false)}
                visible={hetongVsible}
            >
                <Table
                    dataSource={hetongList}
                    rowKey="id"
                    className="table-page"
                    scroll={{x: 900}}
                >
                    <Column title="序号" dataIndex="number" />
                    <Column title="合同号" dataIndex="contractNo" />
                    <Column title="关联主体" dataIndex="accountName" />
                    <Column title="项目" dataIndex="projectNames" />
                    <Column title="产品" dataIndex="productNames" />
                    <Column title="合同领取人" dataIndex="receiver" />
                    <Column title="负责人" dataIndex="leadCadreName" />
                    <Column title="合同期限" render={(record) => {
                        return record.beginTime + ' ~ ' + record.endTime
                    }} />
                </Table>
            </Drawer>

        </Spin>

    )
}

export default Form.create()(Detail);