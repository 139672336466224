/**
 * 模块名称: 代理商公共组件
 * @author yue
 */
import React, {useEffect, useState} from 'react'
import api from '@/api'
import {Alert, Drawer, Spin, Table} from 'antd'

const {Column} = Table

//分页相关数据
let pageSize = 10
let currentPage = 1

//区分各个表格分页
let pageDataOne = {pageSize, currentPage},
    pageDataTwo = {pageSize, currentPage},
    pageDataThree = {pageSize, currentPage},
    pageDataFour = {pageSize, currentPage},
    pageDataFive = {pageSize, currentPage};

//筛选参数
let searchParamsTmp = {
    limit: pageSize,
    page: currentPage,
}

const PublicAgentTransfer = (props) => {
    const {listParams} = props

    useEffect(() => {
        pageSize = 10
        currentPage = 1
        pageDataOne = {pageSize, currentPage}
        pageDataTwo = {pageSize, currentPage}
        pageDataThree = {pageSize, currentPage}
        pageDataFour = {pageSize, currentPage}
        pageDataFive = {pageSize, currentPage}
        searchParamsTmp = {
            limit: pageSize,
            page: currentPage,
        }
       
    }, [])

    useEffect(() => {
        if (listParams !== undefined && listParams.length != 0) {
            initData()
        }
    }, [listParams])

    //初始化
    const initData = () => {
        //查询参数
        searchParamsTmp.qId = listParams.qId;
        searchParamsTmp.transferOutId = listParams.transferOutId;

        if (listParams.snapshotFlag != undefined && listParams.snapshotFlag) {
            pageDataOne['pageSize'] = 9999999999;
            pageDataTwo['pageSize'] = 9999999999;
            pageDataThree['pageSize'] = 9999999999;
            pageDataFour['pageSize'] = 9999999999;
            setDisable(true)

            if (listParams.snapshot.agent != undefined) {
                setListOne(listParams.snapshot.agent.list)
                setCountOne(listParams.snapshot.agent.count)

                setListTwo(listParams.snapshot.invoice.list)
                setCountTwo(listParams.snapshot.invoice.count)

                setListThree(listParams.snapshot.account.list)
                setCountThree(listParams.snapshot.account.count)

                setListFour(listParams.snapshot.credit.list)
                setCountFour(listParams.snapshot.credit.count)
            }

        } else {
            pageDataOne['pageSize'] = pageSize;
            pageDataTwo['pageSize'] = pageSize;
            pageDataThree['pageSize'] = pageSize;
            pageDataFour['pageSize'] = pageSize;

            //获取待转代理商列表
            publicGetList(searchParamsTmp, 'one');

            //获取未收款发票
            publicGetList(searchParamsTmp, 'two');

            //获取账户余额
            publicGetList(searchParamsTmp, 'three');

            //获取信用账户欠款
            publicGetList(searchParamsTmp, 'four');
        }
    }

    //各个列表请求等待标识
    const [LoadingOne, setLoadingOne] = useState(false);
    const [LoadingTwo, setLoadingTwo] = useState(false);
    const [LoadingThree, setLoadingThree] = useState(false);
    const [LoadingFour, setLoadingFour] = useState(false);
    const [LoadingFive, setLoadingFive] = useState(false);
    const [disable, setDisable] = useState(false);

    //获取待转列表
    const [listOne, setListOne] = useState([])
    const [countOne, setCountOne] = useState(0)

    //获取未收款发票列表
    const [listTwo, setListTwo] = useState([])
    const [countTwo, setCountTwo] = useState(0)

    //获取未收款发票列表
    const [listThree, setListThree] = useState([])
    const [countThree, setCountThree] = useState(0)

    //获取信用账户欠款列表
    const [listFour, setListFour] = useState([])
    const [countFour, setCountFour] = useState(0)

    //获取合同列表
    const [contract, setContract] = useState(undefined)
    const [listFive, setListFive] = useState([])
    const [countFive, setCountFive] = useState(0)

    /**
     * 公共获取列表
     * @param params
     * @param dataType
     */
    function publicGetList(params, dataType) {
        let data = publicGetPointer(dataType);

        if (dataType == 'five') {
            data['list']([]);
        }

        data['loading'](true);
        data['apiUrl'](params).then(res => {
            data['list'](res.list);
            data['count'](res.count);
            data['loading'](false);
        })
    }

    /**
     * 公共翻页
     * @param pagination
     * @param dataType
     */
    function publicOnChangeTable(pagination, dataType) {
        let data = publicGetPointer(dataType, 'page');

        searchParamsTmp.page = data['currentPage'] = pagination.current
        searchParamsTmp.limit = data['pageSize'] = pagination.pageSize

        if (dataType == 'five') {
            searchParamsTmp.contract = contract;
        }

        publicGetList(searchParamsTmp, dataType);
    }

    /**
     * 获取数据指针
     * @param dataType
     * @param type
     */
    function publicGetPointer(dataType, type = 'list') {
        let data = {}

        switch (dataType) {
            case 'one':
                if (type == 'list') {
                    data.apiUrl = api.getTransferAgentList;
                    data.list = setListOne;
                    data.count = setCountOne;
                    data.loading = setLoadingOne;
                } else {
                    data = pageDataOne;
                }
                break;
            case 'two':
                if (type == 'list') {
                    data.apiUrl = api.getInvoiceList;
                    data.list = setListTwo;
                    data.count = setCountTwo;
                    data.loading = setLoadingTwo;
                } else {
                    data = pageDataTwo;
                }
                break;
            case 'three':
                if (type == 'list') {
                    data.apiUrl = api.getAccountList;
                    data.list = setListThree;
                    data.count = setCountThree;
                    data.loading = setLoadingThree;
                } else {
                    data = pageDataThree;
                }
                break;
            case 'four':
                if (type == 'list') {
                    data.apiUrl = api.getCreditList;
                    data.list = setListFour;
                    data.count = setCountFour;
                    data.loading = setLoadingFour;
                } else {
                    data = pageDataFour;
                }
                break;
            case 'five':
                if (type == 'list') {
                    data.apiUrl = api.getContractList;
                    data.list = setListFive;
                    data.count = setCountFive;
                    data.loading = setLoadingFive;
                } else {
                    data = pageDataFive;
                }
                break;
        }

        return data;
    }

    /**
     * 公共获取列表
     * @param dataType
     * @param params
     */
    const publicGetListBack = (dataType, params, api) => {
        const apis = {
            //获取待转代理商列表
            'one': 'getTransferAgentList',
            //获取未收款发票
            'two': 'getInvoiceList',
            //获取账户余额
            'three': 'getAccountList',
            //获取信用账户欠款
            'four': 'getCreditList',
            //获取合同列表
            'five': 'getContractList',
        }

        let upperCase = dataType.replace(/^\S/, s => s.toUpperCase());

        if (dataType == 'five') {
            eval(`setList${upperCase}([])`);
        }

        eval(`
            setLoading${upperCase}(true);
            api.${apis[dataType]}(params).then(res => {
                setList${upperCase}(res.list);
                setCount${upperCase}(res.count);
                setLoading${upperCase}(false);
            })
        `);
    }

    /**
     * 公共翻页操作
     * @param pagination
     * @param dataType
     */
    const publicOnChangeTableBack = (pagination, dataType) => {
        let upperCase = dataType.replace(/^\S/, s => s.toUpperCase());

        eval(`currentPage${upperCase} = pagination.current`)
        eval(`pageSize${upperCase} = pagination.pageSize`)

        searchParamsTmp.page = pagination.current
        searchParamsTmp.limit = pagination.pageSize

        if (dataType == 'five') {
            searchParamsTmp.contract = contract;
        }

        publicGetList(dataType, searchParamsTmp, api);
    }

    //侧边栏
    const [visible, setVisible] = useState(false);

    //显示侧边栏
    const showDrawer = (contract) => {
        setVisible(true);
        setLoadingFive(true);
        setContract(contract);

        searchParamsTmp.page = pageDataFive['currentPage'];
        searchParamsTmp.limit = pageDataFive['pageSize']
        searchParamsTmp.contract = contract;

        publicGetList(searchParamsTmp, 'five');
    };

    //关闭侧边栏
    const onClose = () => {
        setVisible(false);
        setLoadingFive(false);
    };

    return (
        <>
            <div style={{fontWeight: 'bold', marginBottom: 4}}>转出信息</div>
            {!LoadingOne && <Alert className="corp-count" message={`总计：${countOne}条数据`} type="info" showIcon/>}
            <Table
                dataSource={listOne}
                rowKey="id"
                loading={LoadingOne}
                className="today-info"
                scroll={{x: 1400}}
                pagination={disable? false:{
                    total: countOne,
                    pageSize: pageDataOne['pageSize'],
                    current: pageDataOne['currentPage'],
                    showQuickJumper: true,
                    showSizeChanger: true,
                    disabled: disable,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                onChange={(value) => publicOnChangeTable(value, 'one')}
            >
                <Column title="序号" dataIndex="number"/>
                <Column title="QID" dataIndex="id"/>
                <Column title="代理商名称" dataIndex="name"/>
                <Column title="认证号码类型" dataIndex="verifyTypeName"/>
                <Column title="认证号码" dataIndex="verifyNumber"/>
                <Column title="合同" dataIndex="contract" render={(text, record) =>
                    <>
                        {
                            <a href="#!" onClick={() => {
                                showDrawer(text)
                            }}>查看</a>
                        }
                    </>
                }/>
                <Column title="负责人（转出）" dataIndex="principalName"/>
            </Table>
            <div style={{fontWeight: 'bold',  marginTop:disable ? 26 : 10, marginBottom: 4}}>未收款发票</div>
            {!LoadingTwo && <Alert className="corp-count" message={`总计：${countTwo}条数据`} type="info" showIcon/>}
            <Table
                dataSource={listTwo}
                rowKey="id"
                loading={LoadingTwo}
                className="today-info"
                scroll={{x: 1400}}
                pagination={disable? false:{
                    total: countTwo,
                    pageSize: pageDataTwo['pageSize'],
                    current: pageDataTwo['currentPage'],
                    showQuickJumper: true,
                    showSizeChanger: true,
                    disabled: disable,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                onChange={(value) => publicOnChangeTable(value, 'two')}
            >
                <Column title="代理商" dataIndex="customerName"/>
                <Column title="QID" dataIndex="customerId"/>
                <Column title="发票抬头" dataIndex="invtitle"/>
                <Column title="发票金额" dataIndex="money"/>
                <Column title="发票类型" dataIndex="invtypeName"/>
                <Column title="发票号" dataIndex="invoiceNumber"/>
                <Column title="收款状态" dataIndex="collestatusName"/>
                <Column title="申请人" dataIndex="leadCadreName"/>
                <Column title="申请时间" dataIndex="addTime"/>
            </Table>

            <div style={{fontWeight: 'bold',  marginTop:disable ? 26 : 10, marginBottom: 4}}>账户余额</div>
            {!LoadingThree && <Alert className="corp-count" message={`总计：${countThree}条数据`} type="info" showIcon/>}
            <Table
                dataSource={listThree}
                rowKey="accountNo"
                loading={LoadingThree}
                className="today-info"
                scroll={{x: 1400}}
                pagination={disable? false:{
                    total: countThree,
                    pageSize: pageDataThree['pageSize'],
                    current: pageDataThree['currentPage'],
                    showQuickJumper: true,
                    showSizeChanger: true,
                    disabled: disable,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                onChange={(value) => publicOnChangeTable(value, 'three')}
            >
                <Column title="账户ID" dataIndex="accountNo"/>
                <Column title="QID" dataIndex="relationId"/>
                <Column title="代理商" dataIndex="agentName"/>
                <Column title="账户" dataIndex="companyName"/>
                <Column title="创建时间" dataIndex="addTime"/>
                <Column title="账户余额" dataIndex="balance"/>
            </Table>
            <div style={{fontWeight: 'bold',  marginTop: disable ? 26 : 10, marginBottom: 4}}>信用账户欠款</div>
            {!LoadingFour && <Alert className="corp-count" message={`总计：${countFour}条数据`} type="info" showIcon/>}
            <Table
                dataSource={listFour}
                rowKey="id"
                loading={LoadingFour}
                className="today-info"
                scroll={{x: 1400}}
                pagination={disable? false:{
                    total: countFour,
                    pageSize: pageDataFour['pageSize'],
                    current: pageDataFour['currentPage'],
                    showQuickJumper: true,
                    showSizeChanger: true,
                    disabled: disable,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                onChange={(value) => publicOnChangeTable(value, 'four')}
            >
                <Column title="账户ID" dataIndex="id"/>
                <Column title="QID" dataIndex="oaqId"/>
                <Column title="代理商" dataIndex="agentName"/>
                <Column title="账户" dataIndex="company"/>
                <Column title="账户类型" dataIndex="accountType"/>
                <Column title="已用额度" dataIndex="usedAmount"/>
                <Column title="到期还款日" dataIndex="endDay"/>
                <Column title="申请人" dataIndex="proposerName"/>
            </Table>
            <Drawer
                title="合同明细记录表"
                width={800}
                onClose={onClose}
                visible={visible}
                destroyOnClose={true}
            >
                <Spin spinning={LoadingFive}>
                    <div style={{padding: '0 0 40px 40px'}}>
                        {!LoadingFive &&
                        <Alert className="corp-count" message={`总计：${countFive}条数据`} type="info" showIcon/>}
                        <Table
                            dataSource={listFive}
                            rowKey="id"
                            className="today-info"
                            scroll={{x: 1400}}
                            pagination={{
                                total: countFive,
                                pageSize: pageDataFive['pageSize'],
                                current: pageDataFive['currentPage'],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                            }}
                            onChange={(value) => publicOnChangeTable(value, 'five')}
                        >
                            <Column title="序号" dataIndex="number"/>
                            <Column title="合同号" dataIndex="contractNo"/>
                            <Column title="关联主体" dataIndex="accountName"/>
                            <Column title="项目" dataIndex="projectNames"/>
                            <Column title="产品" dataIndex="productNames"/>
                            <Column title="合同领取人" dataIndex="receiver"/>
                            <Column title="负责人" dataIndex="leadCadreName"/>
                            <Column title="合同期限" dataIndex="term"/>
                        </Table>
                    </div>
                </Spin>
            </Drawer>
        </>
    )
}
export default PublicAgentTransfer