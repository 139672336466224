/**
 * 模块名称: 代理商转移
 * @author yue
 */

import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import api from '@/api'
import { Link } from 'react-router-dom'
import { Alert, Button, Form, Icon, Input, message, Modal, Popconfirm, Select, Table, Switch } from 'antd'
import CorpFilter from '@/components/CorpFilter'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let corpId = []
let searchParamsTmp = {
	limit: pageSize,
	page: currentPage
}
let storeFilterName = ''
let storeFilterContent = {}

const AgentTransferPage = (props) => {
	const { location, match, history } = props

	const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

	const [count, setCount] = useState(0)
	const [list, setList] = useState([])

	const [loading, setLoading] = useState(false)

	const [filterModelvisible, setFilterModelvisible] = useState(false)
	const [filterContent, setFilterContent] = useState([])

	//负责人（转入、转出）
	const [transferIn, setTransferIn] = useState(undefined)
	const [transferOut, setTransferOut] = useState(undefined)

	const [transferInList, setTransferInList] = useState([])
	const [transferOutList, setTransferOutList] = useState([])

	const [statusValName, setStatusValName] = useState(undefined)
	const [flag, setFlag] = useState(false)
	const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

	const search = parseSearch(location.search)
	currentPage = search.page ? +search.page : currentPage
	pageSize = search.pageSize ? +search.pageSize : pageSize

	useEffect(() => {
		shouldMe = null
		apiOperateTimer = true
		searchParamsTmp = {
			limit: pageSize,
			page: currentPage
		}
		storeFilterName = ''
		storeFilterContent = {}
		getList()
	}, [])

	/**
	 * 公共获取负责人
	 * @param val
	 * @param dataType
	 */
	function publicTransferUserList(val, dataType) {
		let list = publicGetDataPointer(dataType);

		if (val) {
			return api.onOfficeWorkGetStaffByName({ name: val }).then(res => {
				list(res.list);
			})
		} else {
			return (new Promise(function () {
				list([]);
			}));
		}
	}

	/**
	 * 公共操作负责人
	 * @param val
	 * @param dataType
	 */
	function publicHandleTransferVal(val, dataType) {
		let pointer = publicGetDataPointer(dataType, 'val');
		pointer(val);
	}

	/**
	 * 获取数据指针
	 * @param dataType
	 * @param type
	 * @returns {undefined}
	 */
	function publicGetDataPointer(dataType, type = 'list') {
		let pointer = undefined;
		switch (dataType) {
			case 'in':
				pointer = setTransferIn;
				if (type == 'list') {
					pointer = setTransferInList;
				}
				break;
			case 'out':
				pointer = setTransferOut;
				if (type == 'list') {
					pointer = setTransferOutList;
				}
				break;
		}
		return pointer;
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	//点击选择所属公司
	const onChangeCorp = (data) => {
		currentPage = 1;
		if (data.length) {
			let arr = []
			data.forEach((item, index) => {
				arr.push(item.id)
			})
			corpId = [].concat(arr)
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage,
				corpId: corpId
			}
		} else {
			corpId = []
			searchParamsTmp = {
				limit: pageSize,
				page: currentPage
			}
		}
		history.replace(match.path)
		getList()
	}
	//点击重置
	const onReset = () => {
		publicReset()
		history.replace(match.path)
		getList()
	}

	const publicReset = (rest = true) => {
		currentPage = 1;
		shouldMe = null
		searchParamsTmp = {
			limit: pageSize,
			page: currentPage,
			corpId: corpId
		}

		setFieldsValue({ businessNo: '', transferInId: '', transferOutId: '' })

		if (rest) {
			resetFields()
			setFieldsValue({ filterSelect: '' })
		}
		setStatusValName(undefined)
		setTransferIn(undefined);
		setTransferOut(undefined);
		setTransferInList([]);
		setTransferOutList([]);
	}

	//分页点击
	const onChangeTable = (pagination, filters, sorter) => {
		searchParamsTmp.page = currentPage = pagination.current
		searchParamsTmp.limit = pageSize = pagination.pageSize
		history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
		getList()
	}

	//删除请假外出申请
	const onDelete = (id) => {
		api.agentTransferDel({ id }, true).then(res => {
			message.success('删除成功')
			getList()
		})
	}

	//根据条件获取数据列表
	const getList = () => {
		if (apiOperateTimer) {
			setLoading(true)
			if (shouldMe) {
				searchParamsTmp.shouldMe = shouldMe
			} else {
				for (let i in searchParamsTmp) {
					if (i == 'shouldMe') delete searchParamsTmp[i]
				}
			}
			if (corpId.length) searchParamsTmp.corpId = corpId
			api.getAgentTransferList(searchParamsTmp).then(res => {
				setCount(res.count)
				setList(res.list)
				setLoading(false)
			}).catch(() => {
				setLoading(false)
			})
			apiOperateTimer = false
			setShouldMeDis(false)
			let timer = setTimeout(() => {
				apiOperateTimer = true
				setShouldMeDis(true)
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.info('请不要频繁操作')
		}
	}

	const onSetShouldMe = e => {
		if (e == true) {
			shouldMe = 1
		} else {
			shouldMe = null
		}
		getList()
	}

	//数据转换
	const transData = (vals) => {
		if (vals !== undefined) {
			if (vals.businessNo !== undefined) searchParamsTmp.businessNo = vals.businessNo
			if (vals.transferInId !== undefined) {
				let transferInValue = JSON.parse(vals.transferInId);
				searchParamsTmp.transferInId = transferInValue.id
			}
			if (vals.transferOutId !== undefined) {
				let transferOutValue = JSON.parse(vals.transferOutId);
				searchParamsTmp.transferOutId = transferOutValue.id
			}
		}
	}

	//点击筛选
	const searchSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			transData(vals)
			if (!err) {
				searchParamsTmp.page = currentPage = 1
				history.replace(match.path)
				getList()
			}
		})

	}
	//选择保存方案
	const onChangeGetFilter = (val) => {
		val ? setFlag(true) : setFlag(false)
		val && api.getSearchStorage({ key: location.pathname }).then(res => {
			setFilterContent(res.list)
		})
	}
	//选择方案
	const onFilterChecked = (item, val2) => {
		publicReset(false)
		setStatusValName(val2.props.children.props.children[0])
		// let data = JSON.parse(item)
		let data = filterContent[item].content
		if (data.transferInId !== undefined) {
			let transferInValue = JSON.parse(data.transferInId);
			data.transferInValueId = transferInValue.id
			data.transferInValueName = transferInValue.staffName
		}
		if (data.transferOutId !== undefined) {
			let transferOutValue = JSON.parse(data.transferOutId);
			data.transferOutValueId = transferOutValue.id
			data.transferOutValueName = transferOutValue.staffName
		}

		searchParamsTmp.businessNo = data.businessNo
		searchParamsTmp.transferInId = data.transferInValueId
		searchParamsTmp.transferOutId = data.transferOutValueId

		setFieldsValue({
			businessNo: data.businessNo ? data.businessNo : undefined,
		});

		//初始化转入负责人
		if (data.transferInValueName != undefined) {
			publicTransferUserList(data.transferInValueName, 'in').then(res => {
				setFieldsValue({
					transferInId: data.transferInId ? data.transferInId : undefined,
				});
			})
		}

		//初始化转出负责人
		if (data.transferOutValueName != undefined) {
			publicTransferUserList(data.transferOutValueName, 'out').then(res => {
				setFieldsValue({
					transferOutId: data.transferOutId ? data.transferOutId : undefined,
				});
			});
		}

		getList()
	}
	//保存筛选条件
	const onSaveFilterTerm = () => {
		validateFields((err, vals) => {
			if (!err) {
				const values = Object.values(vals)
				if (values.some(item => item === '' || item !== undefined)) {
					storeFilterContent = {}
					for (let k in vals) {//eslint-disable-line
						if (vals[k] !== undefined) {
							storeFilterContent[k] = vals[k]
						}
					}
					setFilterModelvisible(true)
				} else {
					message.error('请选择筛选内容')
				}
			}
		})
	}
	//保存筛选点击确定
	const filterHandleOk = () => {
		validateFields((err, vals) => {
			api.saveSearchStorage({
				name: storeFilterName,
				key: location.pathname,
				content: storeFilterContent
			}).then(() => {
				setFilterModelvisible(false)
				message.success('添加成功')
			})
		})
	}
	//取消筛选点击确定
	const filterHandleCancel = () => {
		setFilterModelvisible(false)
	}

	// 筛选删除
	const onSearchDelete = (id) => {
		api.searchStorageDelete({
			id: id
		}).then(() => {
			api.getSearchStorage({ key: location.pathname }).then(data => {
				setFilterContent(data.list)
			})
		})
	}

	//修改筛选名称
	const onFilterInfoChange = (e) => {
		storeFilterName = e.target.value
	}

	return (
		<>

			<div className="search-askforleave-wrap agentTransfer-form-wrap">
				<Form onSubmit={searchSubmit} {...formItemLayout}>
					<CorpFilter onChange={onChangeCorp} />
					<div className='form-box' style={{ marginTop: 20 }}>
						<FormItem label="业务编号">
							{getFieldDecorator('businessNo')(<Input
								placeholder="请输入业务编号"
							/>)}
						</FormItem>
						<FormItem label="负责人(转出)">
							{getFieldDecorator('transferOutId', {
								initialValue: transferOut,
								rules: [{ required: false, message: '请选择负责人(转出)' }]
							})(
								<Select
									showSearch
									showArrow
									placeholder="请输入负责人(转出)"
									style={{ width: '100%' }}
									onSearch={(value => {
										publicTransferUserList(value, 'out')
									})}
									onChange={(value) => {
										publicHandleTransferVal(value, 'out')
									}}
									notFoundContent='未搜索到相关负责人'
									filterOption={false}
								>
									{transferOutList.map(item => <Option key={item.id} value={JSON.stringify(item)}>{item.staffName}</Option>)}
								</Select>
							)}
						</FormItem>

						<FormItem label="负责人(转入)">
							{getFieldDecorator('transferInId', {
								initialValue: transferIn,
								rules: [{ required: false, message: '请选择负责人(转入)' }]
							})(
								<Select
									showSearch
									showArrow
									placeholder="请输入负责人(转入)"
									style={{ width: '100%' }}
									onSearch={(value => {
										publicTransferUserList(value, 'in')
									})}
									onChange={(value) => {
										publicHandleTransferVal(value, 'in')
									}}
									notFoundContent='未搜索到相关负责人'
									filterOption={false}
								>
									{transferInList.map(item => <Option key={item.id} value={JSON.stringify(item)}>{item.staffName}</Option>)}
								</Select>
							)}
						</FormItem>
					</div>
					<FormItem className="btns" label=" " colon={false}>
						<div className="staff-filter-btns">
							{<>
								<h4 className="filter-title">已保存筛选方案：</h4>
								<div className="filter-select">
									{getFieldDecorator('filterSelect', {})(
										<Select style={{ display: 'inline-block', width: '160px' }}
											placeholder="请选择"
											onDropdownVisibleChange={(e) => onChangeGetFilter(e)}
											onSelect={onFilterChecked}>
											{
												filterContent.map((item, index) =>
													<Option value={index} key={index}>
														<div className="closeStyBtn">
															{item.name}
															{flag && statusValName !== item.name ?
																<span className="closeSpan"><Icon
																	style={{ fontSize: '10px', color: '#1890ff' }}
																	type="close" onClick={e => {
																		e.stopPropagation()
																		onSearchDelete(item.id)
																	}} /></span> : null}
														</div>
													</Option>)
											}
										</Select>
									)}

								</div>
								<Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
							</>}
							<Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
							<Button onClick={onReset}>重置</Button>
							<Button type="primary" htmlType="submit">筛选</Button>
							<Modal
								title="保存筛选条件"
								visible={filterModelvisible}
								onOk={filterHandleOk}
								onCancel={filterHandleCancel}
							>
								<Input placeholder="请输入筛选名称" onChange={onFilterInfoChange} />
							</Modal>
						</div>
					</FormItem>
				</Form>
			</div>
      <div className="line"></div>
      <div className="white-line"></div>
			{!loading && <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />}
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				loading={loading}
				className="askforleave-table"
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				scroll={{ x: 1200 }}
				onChange={onChangeTable}
			>
				<Column title="序号" dataIndex="number" />
				<Column title="业务编号" render={(text, record) =>
					<>
						{
							<Link
								to={text.editFlag ? `/agent/agentTransfer/add?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}` : `/agent/agentTransfer/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.businessNo}</Link>
						}
					</>
				} />
				<Column title="负责人（转出）" dataIndex="transferOutName" />
				<Column title="负责人（转入）" dataIndex="transferInName" />
				<Column title="状态" dataIndex="auditStatusName" />
				<Column title="申请时间" dataIndex="addTime" />
				<Column title="审批时间" dataIndex="auditTime" />
				<Column title="操作" width={150} fixed="right" key="set" render={(text, record) => (
					<>
						{text.delFlag &&
							<Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
								<Icon style={{ color: '#EE4943FF' }} className="operate-icon" type="delete" />
							</Popconfirm>}
					</>
				)} />
			</Table>
		</>
	)
}

export default Form.create()(AgentTransferPage)