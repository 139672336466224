/**
 * 模块名称: 厂商id转移管理管理
 * @author xuxiaobo@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Details from './Details'
import Add from './Add'

const Manufacturer = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        <Route path={match.path + '/details'} component={Details} />
        <Route path={match.path + '/edit'} component={Add} />
        <Route path={match.path + '/add'} component={Add} />
        <Route path={match.path + '/apply'} component={Add} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Manufacturer